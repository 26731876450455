import { DateInput } from '@shared/components/dynamic-form/models/form-date';
import { FormDropdown } from '@shared/components/dynamic-form/models/form-dropdown';
import { generateNumberArray } from '@shared/helpers/variables';
import { FormCascade } from '@shared/components/dynamic-form/models';

const today = new Date();
const oneMonthAgo = new Date(today.getFullYear(), today.getMonth() - 1, today.getDate());
const startDate = new Date(
  Date.UTC(oneMonthAgo.getFullYear(), oneMonthAgo.getMonth(), oneMonthAgo.getDate(), 0, 0, 0),
);
const endDate = new Date(Date.UTC(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0));
const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);

export const filtersDashboards = [
  new DateInput({
    key: 'dateMonth',
    type: 'date',
    required: false,
    showTime: false,
    label: 'DATA.MODULES.REPORTS.FILTERS.DATE_COLLECTION_CREATED',
    value: startDate,
    dateView: 'month',
  }),

  new FormCascade({
    key: 'campaignIdNoRequired',
    type: 'dropdown',
    placeholder: 'Selecciona una campaña',
    required: false,
    value: '',
    label: 'DATA.MODULES.REPORTS.FILTERS.CAMPAIGNS',
    readonly: false,
  }),

  new FormDropdown({
    key: 'months',
    type: 'dropdown',
    required: false,
    label: 'DATA.MODULES.REPORTS.FILTERS.PERIOD',
    value: 6,
    options: generateNumberArray(1, 12),
    readonly: false,
  }),
  new FormDropdown({
    key: 'collectorId',
    type: 'dropdown',
    required: false,
    label: 'DATA.MODULES.REPORTS.FILTERS.COLLECTORS',
    value: '',
    showClear: false,
    options: [],
    multi: false,
    filter: true,
    readonly: false,
  }),

  new FormDropdown({
    key: 'campaignId',
    type: 'dropdown',
    required: false,
    label: 'DATA.MODULES.REPORTS.FILTERS.CAMPAIGNS',
    value: '',
    options: [],
    multi: false,
    filter: true,
    readonly: false,
  }),

  new FormDropdown({
    key: 'portfolioId',
    type: 'dropdown',
    required: false,
    label: 'DATA.MODULES.REPORTS.FILTERS.PORTFOLIOS',
    value: '',
    options: [],
    multi: false,
    filter: true,
    readonly: false,
  }),

  new DateInput({
    key: 'date',
    type: 'date',
    required: false,
    showTime: false,
    label: 'DATA.MODULES.REPORTS.FILTERS.DATE',
    value: [startDate, endDate],
    selectionMode: 'range',
  }),

  new FormDropdown({
    key: 'ignoreOperationStatus',
    type: 'dropdown',
    required: false,
    label: 'DATA.MODULES.REPORTS.FILTERS.STATUS_OPERATION_IGNORE',
    value: null,
    options: [],
    readonly: false,
  }),
];
export const filtersDashboardsCampaigns = [
  new DateInput({
    key: 'dateMonth',
    type: 'date',
    required: false,
    showTime: false,
    label: 'DATA.MODULES.REPORTS.FILTERS.DATE_COLLECTION_CREATED',
    value: startDate,
    dateView: 'month',
  }),

  new FormCascade({
    key: 'campaignId',
    type: 'dropdown',
    required: true,
    value: '',
    label: 'DATA.MODULES.REPORTS.FILTERS.CAMPAIGNS',
    readonly: false,
  }),

  new FormCascade({
    key: 'campaignIdNoRequired',
    type: 'dropdown',
    placeholder: 'Selecciona una campaña',
    required: false,
    value: '',
    label: 'DATA.MODULES.REPORTS.FILTERS.CAMPAIGNS',
    readonly: false,
  }),

  new FormDropdown({
    key: 'months',
    type: 'dropdown',
    required: false,
    label: 'DATA.MODULES.REPORTS.FILTERS.PERIOD',
    value: 6,
    options: generateNumberArray(1, 12),
    readonly: false,
  }),
  new FormDropdown({
    key: 'collectorId',
    type: 'dropdown',
    required: false,
    label: 'DATA.MODULES.REPORTS.FILTERS.COLLECTORS',
    value: '',
    options: [],
    multi: false,
    showClear: false,
    filter: true,
    readonly: false,
  }),

  new FormDropdown({
    key: 'portfolioId',
    type: 'dropdown',
    required: false,
    label: 'DATA.MODULES.REPORTS.FILTERS.PORTFOLIOS',
    value: '',
    options: [],
    multi: false,
    filter: true,
    readonly: false,
  }),

  new DateInput({
    key: 'dateMonthNotification',
    type: 'date',
    required: true,
    showTime: false,
    label: 'FECHA DE NOTIFICACIÓN',
    value: firstDayOfMonth,
    dateView: 'month',
  }),

  new DateInput({
    key: 'date',
    type: 'date',
    required: false,
    showTime: false,
    label: 'DATA.MODULES.REPORTS.FILTERS.DATE',
    value: [startDate, endDate],
    selectionMode: 'range',
  }),

  new FormDropdown({
    key: 'ignoreOperationStatus',
    type: 'dropdown',
    required: false,
    label: 'DATA.MODULES.REPORTS.FILTERS.STATUS_OPERATION_IGNORE',
    value: null,
    options: [],
    readonly: false,
  }),
];

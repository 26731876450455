<div class="col-12 md:col-12 xl:col-12 pay-card__auto card">
   <app-filters-dropdown
       *ngIf="filtersValues['collectors'].length > 0"
      [filterInputs]="generateFilterInputs()"
      (values)="getValues($event)"
      [filterValues]="filtersValues"
   />

   <ng-container *ngIf="url">
      <div *ngIf="noDataFilters" class="spinner-container text-center align-content-center mt-8 h-27rem">
         <div class="text-900 font-bold text-6xl mb-4">Filtros erróneos</div>
         <p class="line-height-3 mt-0 mb-5 text-700 text-xl font-medium">
            Se deben rellenar los filtros adecuadamente para realizar la búsqueda
         </p>
      </div>
      <iframe
         *ngIf="!noDataFilters"
         [src]="url"
         frameborder="0"
         width="100%"
         height="800"
         style="overflow: hidden; transform: scale(0.97); scrollbar-width: thin"
         allowtransparency
      >
      </iframe>
   </ng-container>
</div>

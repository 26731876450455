import { gql } from 'apollo-angular';

export const ADD_COLLECTOR = gql`
  mutation ($input: CreateCollectorInput!) {
    createCollector(input: $input) {
      id
      name
      tenantId
      taxEntityId
      brandId
      alias
      serviceIds
      channelConfigIds
      paymentGatewayIds
      eventHookConfigIds
      feedbackOptionIds
      regionalConfigId
      currency
    }
  }
`;

export const UPDATE_COLLECTOR = gql`
  mutation ($id: ID!, $input: UpdateCollectorInput!) {
    updateCollector(id: $id, input: $input) {
      id
      name
      tenantId
      taxEntityId
      brandId
      alias
      serviceIds
      channelConfigIds
      paymentGatewayIds
      eventHookConfigIds
      feedbackOptionIds
      regionalConfigId
      currency
    }
  }
`;

export const GET_FEEDBACK_CATEGORIES = gql`
  query {
    feedbackOptionCategories {
      id
      name
    }
  }
`;

export const GET_SERVICES_CATEGORIES = gql`
  query {
    serviceCategories {
      id
      name
    }
  }
`;

export const GET_COLLECTORS = gql`
  query ($tenantId: ID!) {
    searchCollectors(tenantId: $tenantId) {
      id
      name
      alias
      tenantId
      taxEntityId
      brandId
      serviceIds
      channelConfigIds
      eventHookConfigIds
      feedbackOptionIds
      paymentGatewayIds
      regionalConfigId
      currency
    }
  }
`;

import { RadioButton } from '@shared/components/dynamic-form/models/radio-button';

export const reminderChannelForm = [
  new RadioButton({
    key: 'channel',
    type: 'radiobox',
    required: true,
    label: 'DATA.MODULES.SHARED.COMPONENTS.SEND_REMINDER.FORM.CHANNEL.LABEL',
    options: [],
    readonly: false,
  }),
];

export type CampaignType = 'drip' | 'pill';

export enum CAMPAIGN_TYPE {
  DRIP = 'drip',
  PILL = 'pill',
}

export enum campaignStatus {
  PUBLISHED = 'published',
  PAUSED = 'paused',
  UNPUBLISHED = 'unpublished',
  DRAFT = 'draft',
}

export type counter = {
  publishedCount: number;
  draftCount: number;
  unPublishedCount: number;
  pillsCount: number;
};

export type status = {
  label: string;
  value: 'published' | 'unpublished' | 'draft' | 'pill';
  count?: number;
};

export const tabStatus: status[] = [
  {
    label: 'DATA.MODULES.CAMPAINGS.LIST.TABLE.TYPES.ACTIVE',
    value: 'published',
  },
  {
    label: 'DATA.MODULES.CAMPAINGS.LIST.TABLE.TYPES.UNPUBLISHED',
    value: 'unpublished',
  },
  {
    label: 'DATA.MODULES.CAMPAINGS.LIST.TABLE.TYPES.DRAFT',
    value: 'draft',
  },
  {
    label: 'DATA.MODULES.CAMPAINGS.LIST.TABLE.TYPES.PILLS',
    value: 'pill',
  },
];

export interface CampaignModel {
  id: string;
  type?: CampaignType;
  name: string;
  purpose: string;
  status: string;
  silenced: boolean;
  collectors?: any;
  includedSegmentIds: string[];
  triggers: string[];
  bucket?: {
    finish: number;
    start: number;
  };
  nodes: {
    nodeId: string;
    type: string;
    position: number;
    parameters: Record<string, any>;
  }[];
}

import { Injectable } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { daysInWeek } from '@shared/helpers/variables';
import {
  NodeConfig,
  WaitDaysConfig,
  WaitDaysOfWeekConfig,
  WaitDaysForDueDateConfig,
  WaitDayOfMonthConfig,
  ConditionalConfig,
  OfferPartialPaymentConfig,
  OfferPaymentAgreementConfig,
  ConditionalSegmentConfig,
  MakeOutBoundCallConfig,
} from '@modules/campaigns/Domain/Interfaces/INodeConfig';
import {
  FormCheckbox,
  FormDropdown,
  FormInputBase,
  FormNumber,
  FormTextbox,
} from '@shared/components/dynamic-form/models';
import { generatedaysOfMonth } from '@shared/helpers/variables';
import { maxSafeIntegerValidator } from '@shared/validators/forms/maxSafeInteger';
import { minElementsInArray } from '@shared/validators/forms/minElementsInArray';
import { generateVoiceDropdownOptions } from '@modules/campaigns/models/call-node.constants';

@Injectable({
  providedIn: 'root',
})
export class NodeFormService {
  constructor(private fb: FormBuilder) {}

  dropdownOptions = generateVoiceDropdownOptions();

  public generateFormControls(
    config: NodeConfig,
    copies: any,
    campaigns: any,
    conditionalParentsNode: any,
    segments: any,
  ): FormInputBase<any>[] {
    const controls: FormInputBase<any>[] = [];
    //TODO: REFACTOR CASES TO SOMETHING ELSE
    if ('partDay' in config && 'waitDays' in config) {
      // Controls for WaitDaysConfig or WaitDaysForDueDateConfig
      controls.push(
        new FormDropdown({
          key: 'partDay',
          type: 'dropdown',
          required: false,
          label: 'Franja horaria de envío',
          value: (config as WaitDaysConfig | WaitDaysForDueDateConfig).partDay,
          options: [
            {
              label: 'La mañana (9h00-14h00)',
              value: 'MORNING',
            },
            {
              label: 'La tarde (14h00-20h00)',
              value: 'EVENING',
            },
            {
              label: 'El día (9h00-20h00)',
              value: 'FULL_DAY',
            },
          ],
          multi: false,
          filter: false,
          readonly: false,
        }),
      );

      controls.push(
        new FormNumber({
          key: 'waitDays',
          type: 'number',
          required: false,
          label: 'DÍAS/S',
          value: (config as WaitDaysConfig | WaitDaysForDueDateConfig).waitDays,
          placeholder: 'Días de espera',
          readonly: false,
        }),
      );
    }
    if ('dayOfWeek' in config) {
      // Controls for WaitDaysOfWeekConfig
      controls.push(
        new FormDropdown({
          key: 'partDay',
          type: 'dropdown',
          required: false,
          label: 'Franja horaria de envío',
          value: (config as WaitDaysOfWeekConfig).partDay,
          options: [
            {
              label: 'La mañana (9h00-14h00)',
              value: 'MORNING',
            },
            {
              label: 'La tarde (14h00-20h00)',
              value: 'EVENING',
            },
            {
              label: 'El día (9h00-20h00)',
              value: 'FULL_DAY',
            },
          ],
          multi: false,
          filter: false,
          readonly: false,
        }),
      );

      controls.push(
        new FormDropdown({
          key: 'dayOfWeek',
          type: 'dropdown',
          required: false,
          label: 'DÍA DE LA SEMANA',
          value: (config as WaitDaysOfWeekConfig).dayOfWeek,
          options: daysInWeek,
          multi: false,
          filter: false,
          placeholder: 'Selecciona una opción',
          readonly: false,
        }),
      );
    }
    if ('dayOfMonth' in config) {
      // Controls for WaitDayOfMonthConfig
      controls.push(
        new FormDropdown({
          key: 'partDay',
          type: 'dropdown',
          required: false,
          label: 'Franja horaria de envío',
          value: (config as WaitDayOfMonthConfig).partDay,
          options: [
            {
              label: 'La mañana (9h00-14h00)',
              value: 'MORNING',
            },
            {
              label: 'La tarde (14h00-20h00)',
              value: 'EVENING',
            },
            {
              label: 'El día (9h00-20h00)',
              value: 'FULL_DAY',
            },
          ],
          multi: false,
          filter: false,
          readonly: false,
        }),
      );

      controls.push(
        new FormDropdown({
          key: 'dayOfMonth',
          type: 'dropdown',
          required: false,
          label: 'DÍA DEL MES',
          value: (config as WaitDayOfMonthConfig).dayOfMonth,
          options: generatedaysOfMonth(),
          multi: false,
          filter: false,
          placeholder: 'Selecciona una opción',
          readonly: false,
        }),
      );
    }
    if ('copyIds' in config) {
      // Controls for SMSConfig, SuperSMSConfig, etc.
      let valueDropdown: string[] = (config as any).copyIds as Array<string>;
      valueDropdown = valueDropdown ? valueDropdown : [];
      const isSuper = (config['super'] as boolean) || config['copyIds'].length > 1;
      controls.push(
        new FormDropdown({
          key: 'copyIds',
          type: 'dropdown',
          required: true,
          label: 'PLANTILLAS',
          options: copies,
          multi: isSuper,
          filter: true,
          readonly: false,
          validators: isSuper ? [minElementsInArray(2)] : [],
          value: valueDropdown.length > 0 ? (isSuper ? valueDropdown : valueDropdown[0]) : null,
        }),
      );
    }

    if ('sendToAllContacts' in config) {
      const sendToAllContacts = Boolean(config['sendToAllContacts']);
      controls.push(
        new FormCheckbox({
          key: 'sendToAllContacts',
          label:
            'Enviar este mensaje a todas las direcciones de canal disponibles para cada cliente',
          value: sendToAllContacts,
          type: 'boolean',
        }),
      );
    }

    if ('copyId' in config && 'voiceId' in config) {
      controls.push(
        new FormDropdown({
          key: 'copyId',
          type: 'text',
          required: true,
          options: [{ label: 'Playbook 1', value: '11e03515-de2a-4e3c-a091-07556779fe44' }],
          multi: false,
          label: 'MENSAJE',
          readonly: false,
          value: (config as MakeOutBoundCallConfig).copyId,
        }),
        new FormDropdown({
          key: 'voiceId',
          type: 'text',
          required: true,
          label: 'VOZ',
          options: this.dropdownOptions,
          multi: false,
          readonly: false,
          value: (config as MakeOutBoundCallConfig).voiceId,
        }),
      );
    }

    if ('targetNodeId' in config && 'statuses' in config) {
      // Controls for ConditionalConfig
      controls.push(
        new FormDropdown({
          key: 'status',
          type: 'dropdown',
          required: false,
          label: 'ESTADOS',
          value: (config as ConditionalConfig).statuses,
          options: [
            { value: 'received', label: 'Recibido' },
            { value: 'read', label: 'Leido' },
            { value: 'error', label: 'Error' },
          ],
          multi: true,
          filter: false,
          placeholder: 'Selecciona una opción',
          readonly: false,
        }),
      );
    }
    if ('targetCampaignId' in config) {
      const valueDropdown: string = (config as any).targetCampaignId as string;
      controls.push(
        new FormDropdown({
          key: 'campañaId',
          type: 'dropdown',
          required: false,
          label: 'ASIGNAR A CAMPAÑA',
          value: valueDropdown,
          options: campaigns,
          multi: false,
          filter: false,
          readonly: false,
        }),
      );
    }
    if ('antesODespues' in config) {
      // Controls for AssignCampaignConfig
      controls.push(
        new FormDropdown({
          key: 'antesODespues',
          type: 'dropdown',
          required: false,
          label: 'ANTES O DESPUÉS DEL VENCIMIENTO',
          value:
            (config as WaitDaysConfig | WaitDaysForDueDateConfig).waitDays !== null
              ? (config as WaitDaysConfig | WaitDaysForDueDateConfig).waitDays! > 0
                ? 'afterDueDate'
                : 'beforeDueDate'
              : null,

          options: [
            { label: 'Antes', value: 'beforeDueDate' },
            {
              label: 'Después',
              value: 'afterDueDate',
            },
          ],
          multi: false,
          filter: false,
          placeholder: 'Selecciona una opción',
          readonly: false,
        }),
      );
    }

    if ('numberOfInstalments' in config && 'expirationDays' in config) {
      controls.push(
        new FormDropdown({
          key: 'numberOfInstalments',
          type: 'number',
          required: true,
          label: 'NÚMERO DE CUOTAS',
          value: (config as OfferPaymentAgreementConfig).numberOfInstalments,
          placeholder: '',
          multi: false,
          filter: false,
          readonly: false,
          options: Array.from({ length: 11 }, (_, i) => i + 2),
        }),
      );

      controls.push(
        new FormNumber({
          key: 'expirationDays',
          type: 'number',
          placeholder: 'Duración en días',
          required: true,
          label: 'CADUCIDAD',
          value: (config as OfferPaymentAgreementConfig).expirationDays,
          readonly: false,
          disabled: false,
          validators: [Validators.min(1), Validators.max(90)],
        }),
      );
    }

    if ('segmentIds' in config) {
      const segmentIds = (config as ConditionalSegmentConfig).segmentIds || [];
      controls.push(
        new FormDropdown({
          key: 'segmentIds',
          type: 'dropdown',
          required: true,
          label: 'SEGMENTO',
          options: segments,
          multi: false,
          filter: false,
          readonly: false,
          value: segmentIds.length > 0 ? segmentIds[0] : null,
        }),
      );
    }

    if (
      'minimumAmount' in config &&
      'minimumPercentageAmount' in config &&
      'expirationDays' in config
    ) {
      controls.push(
        new FormNumber({
          key: 'amount',
          type: 'number',
          required: true,
          label: 'IMPORTE MÍNIMO',
          value: (config as OfferPartialPaymentConfig).minimumAmount.amount,
          placeholder: '',
          readonly: false,
          inputGroup: true,
          addon: 'EUR',
          validators: [maxSafeIntegerValidator()],
        }),
      );
      controls.push(
        new FormNumber({
          key: 'minimumPercentageAmount',
          type: 'number',
          required: false,
          label: 'IMPORTE MÍNIMO EN %',
          value: (config as OfferPartialPaymentConfig).minimumPercentageAmount,
          placeholder: '',
          readonly: false,
          inputGroup: true,
          isVisible: false,
          addon: '%',
          validators: [Validators.pattern('^(\\d|[1-9]\\d|99)$'), Validators.min(1)],
        }),
      );

      controls.push(
        new FormNumber({
          key: 'expirationDays',
          type: 'number',
          placeholder: 'Duración en días',
          required: false,
          label: 'CADUCIDAD',
          value: (config as OfferPartialPaymentConfig).expirationDays,
          readonly: false,
          disabled: false,
          validators: [Validators.min(1), Validators.max(90)],
        }),
      );
    }

    return controls;
  }
}

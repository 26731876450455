const genderIcons: { [key: string]: string } = {
  female: '♀️',
  male: '♂️',
};

const countryFlags: { [key: string]: string } = {
  es_ES: '🇪🇸',
  en_US: '🇺🇸',
  en_GB: '🇬🇧',
  pt_BR: '🇧🇷',
  fr_FR: '🇫🇷',
  it_IT: '🇮🇹',
  de_DE: '🇩🇪',
  es_AR: '🇦🇷',
  pt_PT: '🇵🇹',
};

const voices = [
  { id: '1', name: 'Karla', gender: 'female', locales: ['es_ES', 'en_US', 'en_GB'] },
  { id: '2', name: 'Marta', gender: 'female', locales: ['es_ES', 'en_US', 'en_GB'] },
  { id: '3', name: 'Laura', gender: 'female', locales: ['es_ES'] },
  { id: '4', name: 'Sofía', gender: 'female', locales: ['es_ES'] },
  { id: '5', name: 'Claudia', gender: 'female', locales: ['es_ES'] },
  { id: '6', name: 'Maria', gender: 'female', locales: ['es_ES'] },
  { id: '7', name: 'Lucía', gender: 'female', locales: ['es_ES'] },
  { id: '8', name: 'Martina', gender: 'female', locales: ['es_ES'] },
  { id: '9', name: 'Daniel', gender: 'male', locales: ['es_ES'] },
  { id: '10', name: 'Victor', gender: 'male', locales: ['es_ES'] },
  { id: '11', name: 'Alejandro', gender: 'male', locales: ['es_ES'] },
  { id: '12', name: 'Adrián', gender: 'male', locales: ['es_ES'] },
  { id: '13', name: 'Carolina', gender: 'female', locales: ['es_ES'] },
  { id: '14', name: 'Valeria', gender: 'female', locales: ['es_AR'] },
  { id: '15', name: 'Andreia', gender: 'female', locales: ['pt_BR'] },
  { id: '16', name: 'Sophie', gender: 'female', locales: ['fr_FR'] },
  { id: '17', name: 'Giulia', gender: 'female', locales: ['it_IT'] },
  { id: '18', name: 'Carola', gender: 'female', locales: ['de_DE'] },
  { id: '19', name: 'Santiago', gender: 'male', locales: ['es_ES'] },
  { id: '20', name: 'Alicia', gender: 'female', locales: ['es_ES'] },
  { id: '21', name: 'Nacho', gender: 'male', locales: ['es_ES'] },
  { id: '22', name: 'Guadalupe', gender: 'female', locales: ['es_ES'] },
  { id: '23', name: 'Linda', gender: 'female', locales: ['es_ES'] },
  { id: '24', name: 'Natalia', gender: 'female', locales: ['es_ES'] },
  { id: '25', name: 'Alex', gender: 'male', locales: ['es_ES'] },
  { id: '26', name: 'Paola', gender: 'female', locales: ['es_ES'] },
  { id: '27', name: 'Ana', gender: 'female', locales: ['es_ES'] },
  { id: '28', name: 'Renata', gender: 'female', locales: ['pt_BR'] },
  { id: '29', name: 'Joana', gender: 'female', locales: ['pt_PT'] },
];

export function generateVoiceDropdownOptions() {
  return voices.map((voice) => {
    const genderIcon = genderIcons[voice.gender] || '';
    const flags = voice.locales.map((locale) => countryFlags[locale] || '').join(' ');
    const label = `${genderIcon} ${voice.name} (${flags})`;

    return {
      value: voice.id,
      label,
    };
  });
}

import { Component } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { FormInputBase } from '../../../dynamic-form/models/form-input-base';

import { CustomerModel, CustomerCase } from '@models/customer.model';
import { reminderChannelForm } from '../sendReminderForm';
import { CustomerService } from '@modules/customers/customer.service';

@Component({
  selector: 'app-send-reminder-modal',
  templateUrl: './send-reminder-modal.component.html',
})
export class SendReminderModalComponent {
  reminderChannel: FormInputBase<string | boolean>[] = reminderChannelForm;
  customer: CustomerModel = {} as CustomerModel;
  customerCase: CustomerCase = {} as CustomerCase;
  sms: string = '';
  email: string = '';
  methods = [
    { value: 'sms', label: 'SMS' },
    { value: 'email', label: 'Email' },
  ];

  constructor(
    private customerService: CustomerService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
  ) {}

  ngOnInit(): void {
    this.initVariables();
  }

  initVariables() {
    this.customerCase = this.config.data.customerCase;
    this.customerService.getCustomer(this.customerCase.customerId).then((res: any) => {
      this.customer = res;
      this.reminderChannel
        ?.find((i) => i.key === 'channel')
        ?.setOptions(this.setReminderChannels());
    });
  }

  setReminderChannels() {
    const conditions: Record<string, (customer: CustomerModel) => boolean> = {
      sms: (customer) => customer!.phones!.length > 0,
      email: (customer) => customer!.emailAddresses!.length > 0,
    };

    return this.methods.filter((method) => conditions[method.value]?.(this.customer));
  }

  send() {
    this.ref.close({
      sms: this.sms,
      email: this.email,
      customerCase: this.customerCase,
      customer: this.customer,
    });
  }

  onChanges(data: any) {
    this.sms = '';
    this.email = '';

    const { channel, instalments } = data;
    if (channel !== '') {
      this.handleChannel(channel);
    }
  }

  private handleChannel(channelName: string): void {
    switch (channelName) {
      case 'sms':
        this.setFirstPhoneIfAvailable();
        break;
      case 'email':
        this.setFirstEmailIfAvailable();
        break;
    }
  }

  private setFirstPhoneIfAvailable(): void {
    const { phones } = this.customer;
    if (Array.isArray(phones) && phones.length > 0) {
      this.sms = phones[0];
    }
  }

  private setFirstEmailIfAvailable(): void {
    const { emailAddresses } = this.customer;
    if (Array.isArray(emailAddresses) && emailAddresses.length > 0) {
      this.email = emailAddresses[0];
    }
  }
}

export const STATUS = {
  AGREEMENT: 'agreement',
  ARCHIVED: 'archived',
  CHARGED: 'charged',
  CLOSE: 'close',
  CLOSED: 'closed',
  COMMITTING: 'committing',
  COMMITTED: 'committed',
  CREATED: 'created',
  DRAFT: 'draft',
  DISCARDED: 'discarded',
  FAILED: 'failed',
  GENERATED: 'generated',
  GENERATING: 'generating',
  IN_ALLEGATIONS: 'in_allegations',
  INVALID_FILE: 'invalid_file',
  NOT_VALID: 'not_valid',
  ON_HOLD: 'on_hold',
  OPEN: 'open',
  OPENED: 'opened',
  PENDING: 'pending',
  PROCESSING: 'processing',
  QUEUED: 'queued',
  SILENCED: 'silenced',
  VALID: 'valid',
  WORKED: 'worked',
  //CAMPAIGNS
  PAUSED: 'paused',
  PUBLISHED: 'published',
  UNPUBLISHED: 'unpublished',
  //CAMPAIGNS COLLECTORS
  RUNNING: 'running',
  EXPIRED: 'expired',
  PROPOSAL: 'proposal',
  SIGNED: 'signed',
  FULFILLED: 'fulfilled',
  BROKEN: 'broken',
  //PILLS
  STARTED: 'started',
};

export const STATUS_COLORS: Record<
  string,
  'success' | 'secondary' | 'info' | 'warning' | 'danger' | 'contrast'
> = {
  AGREEMENT: 'success',
  ARCHIVED: 'info',
  CHARGED: 'success',
  CLOSE: 'danger',
  CLOSED: 'danger',
  COMMITTING: 'warning',
  COMMITTED: 'success',
  DISCARDED: 'danger',
  DRAFT: 'info',
  FAILED: 'danger',
  GENERATED: 'success',
  GENERATING: 'warning',
  IN_ALLEGATIONS: 'warning',
  INVALID_FILE: 'danger',
  NOT_VALID: 'danger',
  ON_HOLD: 'warning',
  OPEN: 'info',
  OPENED: 'info',
  PENDING: 'secondary',
  PROCESSING: 'warning',
  QUEUED: 'warning',
  SILENCED: 'warning',
  VALID: 'success',
  WORKED: 'warning',
  // CAMPAIGNS
  PAUSED: 'warning',
  PUBLISHED: 'success',
  UNPUBLISHED: 'danger',
  // CAMPAIGNS COLLECTORS
  RUNNING: 'success',
  EXPIRED: 'danger',
  PROPOSTAL: 'warning',
  SIGNED: 'success',
  FULLFILLED: 'success',
  BROKEN: 'warning',
  // PILLS
  CREATED: 'warning',
  STARTED: 'success',
};

export const TYPE_NOTIFICATION = {
  REMINDER: 'reminder',
};

export const DOWNLOAD_FORMAT_TYPE = {
  CSV: 'csv',
  PDF: 'pdf',
  XLSX: 'xlsx',
};

export const PAYMENT_METHODS = {
  BANK_TRANSFER: 'BANK_TRANSFER',
  CASH: 'CASH',
  CREDIT_CARD: 'CREDIT_CARD',
  DIRECT_DEBIT: 'DIRECT_DEBIT',
  OTHER: 'OTHER',
};

export const DEFAULT_LANGUAGE = {
  ES: 'es',
  EN: 'en',
  CA: 'ca',
  FR: 'fr',
  CV: 'cv',
};

export const GENDERS = {
  FEMALE: 'female',
  MALE: 'male',
  NOT_BINARY: 'non_binary',
  NOT_KNOW: 'not_known',
};

export const STATUS_COLORS_TAG = {
  DEFAULT_INFO: 'info_default',
  AGREEMENT: 'success',
  ARCHIVED: 'processed',
  CHARGED: 'success',
  CLOSE: 'danger',
  DOWN: 'danger',
  UP: 'success',
  CLOSED: 'danger',
  COMMITTING: 'processed',
  COMMITTED: 'success',
  DISCARDED: 'danger',
  DRAFT: 'processed',
  FAILED: 'danger',
  GENERATED: 'success',
  GENERATING: 'processed',
  IN_ALLEGATIONS: 'warning',
  INVALID_FILE: 'danger',
  NOT_VALID: 'danger',
  ON_HOLD: 'processed',
  OPEN: 'info',
  OPENED: 'primary',
  PENDING: 'processed',
  PROCESSING: 'warning',
  QUEUED: 'processed',
  SILENCED: 'warning',
  VALID: 'success',
  WORKED: 'processed',
  //CAMPAIGNS
  PAUSED: 'processed',
  PUBLISHED: 'success',
  UNPUBLISHED: 'warning',
  //CAMPAIGNS COLLECTORS
  RUNNING: 'success',
  EXPIRED: 'danger',
  PROPOSTAL: 'processed',
  SIGNED: 'primary',
  FULLFILLED: 'info',
  BROKEN: 'warning',
  //PILLS
  CREATED: 'primary',
  STARTED: 'success',
  //TELF
  NOTIFYING: 'info',
  BLACKLIST: 'blacked',
  END: 'danger',
  //FEE
  DEFAULT: 'primary',
  NONE: 'warning',
};

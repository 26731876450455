import { CAMPAIGN_TYPE } from '@modules/campaigns/models/campaign.model';

export const reminderOptions: any = [
  {
    value: 'email',
    label: 'Email',
  },
  {
    value: 'sms',
    label: 'SMS',
  },
];

export const monthsInSpanish = [
  'Enero',
  'Febrero',
  'Marzo',
  'Abril',
  'Mayo',
  'Junio',
  'Julio',
  'Agosto',
  'Septiembre',
  'Octubre',
  'Noviembre',
  'Diciembre',
];

export const copiesChannelList: any = [
  {
    value: 'email',
    label: 'Email',
  },
  {
    value: 'sms',
    label: 'SMS',
  },
  {
    value: 'certified_email',
    label: 'Email certificado',
  },
  {
    value: 'certified_sms',
    label: 'SMS certificado',
  },
];

export const nodeSendTypes: any = [
  {
    value: 'sendEmail',
    label: 'Enviar email',
  },
  {
    value: 'sendSMS',
    label: 'Enviar SMS',
  },
  {
    value: 'sendCertifiedEmail',
    label: 'Enviar email certificado',
  },
  {
    value: 'sendCertifiedSMS',
    label: 'Enviar SMS certificado',
  },
];

export const certifiedEmailOption = {
  label: 'Email certificado',
  value: 'certified_email',
};

export const certifiedSmsOption = {
  label: 'SMS certificado',
  value: 'certified_sms',
};

export const sectorList: any = [
  {
    label: 'Energía',
    value: 'Energía',
  },
  {
    label: 'Finanzas',
    value: 'Finanzas',
  },
  {
    label: 'Seguros',
    value: 'Seguros',
  },
  {
    label: 'Telecomunicaciones',
    value: 'Telecomunicaciones',
  },
  {
    label: 'Gimnasios',
    value: 'Gimnasios',
  },
  {
    label: 'Trasteros',
    value: 'Trasteros',
  },
  {
    label: 'Utilities',
    value: 'Utilities',
  },
];

export const treatmentList: any = [
  {
    label: 'Tú',
    value: 'tu',
  },
  {
    label: 'Usted',
    value: 'usted',
  },
];

export const toneList: any = [
  {
    label: 'Baja',
    value: 'bajo',
  },

  {
    label: 'Medio',
    value: 'medio',
  },
  {
    label: 'Alto',
    value: 'Alto',
  },
];

export const targetList: any = [
  { value: 'Amistoso', label: 'Amistoso' },
  { value: ' Recordatorio', label: 'Recordatorio' },
  {
    value: 'Aviso suspensión de servicios',
    label: 'Aviso suspensión de servicios',
  },
  {
    value: 'Confirmación suspensión de servicios',
    label: 'Confirmación suspensión de servicios',
  },
  {
    value: 'Aviso baja definitiva del servicio',
    label: 'Aviso baja definitiva del servicio',
  },
  {
    value: 'Confirmación baja definitiva del servicio',
    label: 'Confirmación baja definitiva del servicio',
  },
  {
    value: 'Aviso finalización de contrato',
    label: 'Aviso finalización de contrato',
  },
  {
    value: 'Confirmación finalización de contrato',
    label: 'Confirmación finalización de contrato',
  },
  {
    value: 'Aviso de inclusión en las bbdd públicas de deudores (asnef/badexcug)',
    label: 'Aviso de inclusión en las bbdd públicas de deudores (asnef/badexcug)',
  },
  {
    value: 'Aviso posibles incidencias legales',
    label: 'Aviso posibles incidencias legales',
  },
  {
    value: 'Confirmación incidencias legales',
    label: 'Confirmación incidencias legales',
  },
];

export const placeholdersList = [
  {
    label: '%nombre corto%',
    value: '%short_name%',
  },
  {
    label: '%nombre largo%',
    value: '%long_name%',
  },
  {
    label: '%nombre entidad fiscal%',
    value: '%tax_entity_society%',
  },
  {
    label: '%cif entidad fiscal%',
    value: '%tax_entity_vat_number%',
  },
  {
    label: '%importe caso%',
    value: '%case_amount%',
  },
  {
    label: '%fecha de envío%',
    value: '%send_date%',
  },
  {
    label: '%marca%',
    value: '%brand%',
  },
  {
    label: '%pending_operations%',
    value: '%pending_operations%',
  },
];

export const purpose = [
  {
    phase: 'Cobro',
    children: [
      {
        label: 'Configurar datos facturación',
        value: 'configure-billing-data',
        data: 'configure-billing-data',
        success: 'Rellenar formulario',
      },
      {
        label: 'Configurar vías de pago',
        value: 'configure-payment-methods',
        data: 'configure-payment-methods',
        success: 'Rellenar formulario',
      },
      {
        label: 'Aviso de un próximo pago',
        value: 'next-payment-alert',
        data: 'next-payment-alert',
        success: 'Mensaje leído',
      },
      {
        label: 'Cobro del servicio',
        value: 'charge-service',
        data: 'charge-service',
        success: 'Pago',
      },
      {
        label: 'Agradecimiento al pago',
        value: 'payment-thank-you',
        data: 'payment-thank-you',
        success: 'Mensaje leído',
      },
      {
        label: 'Obtener NPS servicio',
        value: 'get-service-nps',
        data: 'get-service-nps',
        success: 'Rellenar formulario',
      },
      {
        label: 'Plan fidelización',
        value: 'loyalty-plan',
        data: 'loyalty-plan',
        success: 'Aplicar plan',
      },
      {
        label: 'Upsell',
        value: 'upsell',
        data: 'upsell',
        success: 'Clic intención',
      },
      {
        label: 'Crossell',
        value: 'cross-sell',
        data: 'cross-sell',
        success: 'Clic intención',
      },
      {
        label: 'Flexibilizar pago',
        value: 'flexibilize-payment',
        data: 'flexibilize-payment',
        success: 'Firmar plan de pago',
      },
    ],
  },
  {
    phase: 'Recobro',

    children: [
      {
        label: 'Obtener feedback',
        value: 'get-feedback',
        data: 'get-feedback',
        success: 'Rellenar formulario',
      },
      {
        label: 'Rellenar mandato SEPA',
        value: 'fill-sepa-mandate',
        data: 'fill-sepa-mandate',
        success: 'Rellenar formulario',
      },
      {
        label: 'Cobro de servicio',
        value: 'service-charge',
        data: 'service-charge',
        success: 'Pago',
      },
      {
        label: 'Flexibilizar pago',
        value: 'flexibilize-payment',
        data: 'flexibilize-payment',
        success: 'Firmar plan de pago',
      },
      {
        label: 'Captar datos de los clientes',
        value: 'capture-customer-data',
        data: 'capture-customer-data',
        success: 'Rellenar formulario',
      },
    ],
  },
  {
    phase: 'Legal',
    children: [
      {
        label: 'Comunicación certificada',
        value: 'certified-communication',
        data: 'certified-communication',
        success: 'Mensaje leído',
      },
      {
        label: 'Envío demanda',
        value: 'send-demand',
        data: 'send-demand',
        success: 'Mensaje leído',
      },
      {
        label: 'Recuperación IVA',
        value: 'vat-recovery',
        data: 'vat-recovery',
        success: 'Mensaje leído',
      },
    ],
  },
];

export const type = [
  { label: 'Continua', value: CAMPAIGN_TYPE.DRIP },
  { label: 'Píldora', value: CAMPAIGN_TYPE.PILL },
];

export const eventsAction = [{ label: 'Abandono de pasarela', value: 'payment.abandoned' }];

export function generatedaysOfMonth(): {
  label: string;
  value: number | string;
}[] {
  const days = [];

  for (let i = 1; i <= 30; i++) {
    days.push({ label: i.toString(), value: i });
  }

  days.push({ label: 'Último día del mes', value: 'last' });

  return days;
}

export const daysInWeek = [
  {
    label: 'Lunes',
    value: 'MONDAY',
  },
  {
    label: 'Martes',
    value: 'TUESDAY',
  },
  {
    label: 'Miércoles',
    value: 'WEDNESDAY',
  },
  {
    label: 'Jueves',
    value: 'THURSDAY',
  },
  {
    label: 'Viernes',
    value: 'FRIDAY',
  },
  {
    label: 'Sábado',
    value: 'SATURDAY',
  },
  {
    label: 'Domingo',
    value: 'SUNDAY',
  },
];

export const daysInWeekOnNumber = [
  {
    label: 'Lunes',
    value: 1,
  },
  {
    label: 'Martes',
    value: 2,
  },
  {
    label: 'Miércoles',
    value: 3,
  },
  {
    label: 'Jueves',
    value: 4,
  },
  {
    label: 'Viernes',
    value: 5,
  },
  {
    label: 'Sábado',
    value: 6,
  },
  {
    label: 'Domingo',
    value: 7,
  },
];

export function generateNumberArray(init = 18, end = 120): { label: string; value: number }[] {
  const ageArray = [];

  for (let i = init; i <= end; i++) {
    ageArray.push({ label: i.toString(), value: i });
  }

  return ageArray;
}

export const conditionSelectorOptions = [
  {
    label: 'Antigüedad de la deuda',
    value: 'customer_case_overdue_days',
  },
  {
    label: 'Carteras',
    value: 'portfolio_ids',
  },
  {
    label: 'Edad del cliente',
    value: 'customer_age',
  },
  {
    label: 'Género',
    value: 'customer_gender',
  },
  {
    label: 'Importe Pendiente',
    value: 'customer_case_pending_amount',
  },
  {
    label: 'Perfiles de cobro',
    value: 'collector_ids',
  },
  {
    label: 'Titularidad',
    value: 'customer_is_company',
  },
];

export const paymentsMethods = [
  {
    id: 'CASH',
    name: 'Efectivo',
  },
  {
    id: 'BANK_TRANSFER',
    name: 'Transferencia',
  },
  {
    id: 'CREDIT_CARD',
    name: 'Transferencia de crédito',
  },
  {
    id: 'DIRECT_DEBIT',
    name: 'Transferencia de débito',
  },
  {
    id: 'PAYMEFY',
    name: 'Paymefy',
  },
  {
    id: 'OTHER',
    name: 'Otros',
  },
];

export const isCompany = [
  { value: 'false', label: 'Particular' },
  { value: 'true', label: 'Empresa' },
];

export const status = [
  { value: 'open', label: 'Abierta' },
  { value: 'closed', label: 'Cerrado' },
  { value: 'silenced', label: 'Silenciado' },
  { value: 'worked', label: 'Trabajado' },
];

export const roles = [
  { value: 'superadmin', label: 'Superadmin' },
  { value: 'admin', label: 'Admin' },
  { value: 'manager', label: 'Manager' },
  { value: 'agent', label: 'Agente' },
];

export const tiomezoneListPHP = {
  AF: {
    name: 'Afghanistan',
    timezones: { 'Asia/Kabul': { abbr: 'AFT', offset: 16200, name: 'Kabul' } },
  },
  AL: {
    name: 'Albania',
    timezones: { 'Europe/Tirane': { abbr: 'CET', offset: 3600, name: 'Tirane' } },
  },
  DZ: {
    name: 'Algeria',
    timezones: { 'Africa/Algiers': { abbr: 'CET', offset: 3600, name: 'Algiers' } },
  },
  AS: {
    name: 'American Samoa',
    timezones: { 'Pacific/Pago_Pago': { abbr: 'SST', offset: -39600, name: 'Pago Pago' } },
  },
  AD: {
    name: 'Andorra',
    timezones: { 'Europe/Andorra': { abbr: 'CET', offset: 3600, name: 'Andorra' } },
  },
  AO: {
    name: 'Angola',
    timezones: { 'Africa/Luanda': { abbr: 'WAT', offset: 3600, name: 'Luanda' } },
  },
  AI: {
    name: 'Anguilla',
    timezones: { 'America/Anguilla': { abbr: 'AST', offset: -14400, name: 'Anguilla' } },
  },
  AQ: {
    name: 'Antarctica',
    timezones: {
      'Antarctica/Casey': { abbr: 'WST', offset: 28800, name: 'Casey' },
      'Antarctica/Davis': { abbr: 'DAVT', offset: 25200, name: 'Davis' },
      'Antarctica/DumontDUrville': { abbr: 'DDUT', offset: 36000, name: 'DumontDUrville' },
      'Antarctica/Macquarie': { abbr: 'MIST', offset: 39600, name: 'Macquarie' },
      'Antarctica/Mawson': { abbr: 'MAWT', offset: 18000, name: 'Mawson' },
      'Antarctica/McMurdo': { abbr: 'NZDT', offset: 46800, name: 'McMurdo' },
      'Antarctica/Palmer': { abbr: 'CLST', offset: -10800, name: 'Palmer' },
      'Antarctica/Rothera': { abbr: 'ROTT', offset: -10800, name: 'Rothera' },
      'Antarctica/South_Pole': { abbr: 'NZDT', offset: 46800, name: 'South Pole' },
      'Antarctica/Syowa': { abbr: 'SYOT', offset: 10800, name: 'Syowa' },
      'Antarctica/Vostok': { abbr: 'VOST', offset: 21600, name: 'Vostok' },
    },
  },
  AG: {
    name: 'Antigua and Barbuda',
    timezones: { 'America/Antigua': { abbr: 'AST', offset: -14400, name: 'Antigua' } },
  },
  AR: {
    name: 'Argentina',
    timezones: {
      'America/Argentina/Buenos_Aires': { abbr: 'ART', offset: -10800, name: 'Buenos Aires' },
      'America/Argentina/Catamarca': { abbr: 'ART', offset: -10800, name: 'Catamarca' },
      'America/Argentina/Cordoba': { abbr: 'ART', offset: -10800, name: 'Cordoba' },
      'America/Argentina/Jujuy': { abbr: 'ART', offset: -10800, name: 'Jujuy' },
      'America/Argentina/La_Rioja': { abbr: 'ART', offset: -10800, name: 'La Rioja' },
      'America/Argentina/Mendoza': { abbr: 'ART', offset: -10800, name: 'Mendoza' },
      'America/Argentina/Rio_Gallegos': { abbr: 'ART', offset: -10800, name: 'Rio Gallegos' },
      'America/Argentina/Salta': { abbr: 'ART', offset: -10800, name: 'Salta' },
      'America/Argentina/San_Juan': { abbr: 'ART', offset: -10800, name: 'San Juan' },
      'America/Argentina/San_Luis': { abbr: 'WARST', offset: -10800, name: 'San Luis' },
      'America/Argentina/Tucuman': { abbr: 'ART', offset: -10800, name: 'Tucuman' },
      'America/Argentina/Ushuaia': { abbr: 'ART', offset: -10800, name: 'Ushuaia' },
    },
  },
  AM: {
    name: 'Armenia',
    timezones: { 'Asia/Yerevan': { abbr: 'AMT', offset: 14400, name: 'Yerevan' } },
  },
  AW: {
    name: 'Aruba',
    timezones: { 'America/Aruba': { abbr: 'AST', offset: -14400, name: 'Aruba' } },
  },
  AU: {
    name: 'Australia',
    timezones: {
      'Australia/Adelaide': { abbr: 'CST', offset: 37800, name: 'Adelaide' },
      'Australia/Brisbane': { abbr: 'EST', offset: 36000, name: 'Brisbane' },
      'Australia/Broken_Hill': { abbr: 'CST', offset: 37800, name: 'Broken Hill' },
      'Australia/Currie': { abbr: 'EST', offset: 39600, name: 'Currie' },
      'Australia/Darwin': { abbr: 'CST', offset: 34200, name: 'Darwin' },
      'Australia/Eucla': { abbr: 'CWST', offset: 31500, name: 'Eucla' },
      'Australia/Hobart': { abbr: 'EST', offset: 39600, name: 'Hobart' },
      'Australia/Lindeman': { abbr: 'EST', offset: 36000, name: 'Lindeman' },
      'Australia/Lord_Howe': { abbr: 'LHST', offset: 39600, name: 'Lord Howe' },
      'Australia/Melbourne': { abbr: 'EST', offset: 39600, name: 'Melbourne' },
      'Australia/Perth': { abbr: 'WST', offset: 28800, name: 'Perth' },
      'Australia/Sydney': { abbr: 'EST', offset: 39600, name: 'Sydney' },
    },
  },
  AT: {
    name: 'Austria',
    timezones: { 'Europe/Vienna': { abbr: 'CET', offset: 3600, name: 'Vienna' } },
  },
  AZ: {
    name: 'Azerbaijan',
    timezones: { 'Asia/Baku': { abbr: 'AZT', offset: 14400, name: 'Baku' } },
  },
  BS: {
    name: 'Bahamas',
    timezones: { 'America/Nassau': { abbr: 'EST', offset: -18000, name: 'Nassau' } },
  },
  BH: {
    name: 'Bahrain',
    timezones: { 'Asia/Bahrain': { abbr: 'AST', offset: 10800, name: 'Bahrain' } },
  },
  BD: {
    name: 'Bangladesh',
    timezones: { 'Asia/Dhaka': { abbr: 'BDT', offset: 21600, name: 'Dhaka' } },
  },
  BB: {
    name: 'Barbados',
    timezones: { 'America/Barbados': { abbr: 'AST', offset: -14400, name: 'Barbados' } },
  },
  BY: {
    name: 'Belarus',
    timezones: { 'Europe/Minsk': { abbr: 'EET', offset: 7200, name: 'Minsk' } },
  },
  BE: {
    name: 'Belgium',
    timezones: { 'Europe/Brussels': { abbr: 'CET', offset: 3600, name: 'Brussels' } },
  },
  BZ: {
    name: 'Belize',
    timezones: { 'America/Belize': { abbr: 'CST', offset: -21600, name: 'Belize' } },
  },
  BJ: {
    name: 'Benin',
    timezones: { 'Africa/Porto-Novo': { abbr: 'WAT', offset: 3600, name: 'Porto-Novo' } },
  },
  BM: {
    name: 'Bermuda',
    timezones: { 'Atlantic/Bermuda': { abbr: 'AST', offset: -14400, name: 'Bermuda' } },
  },
  BT: {
    name: 'Bhutan',
    timezones: { 'Asia/Thimphu': { abbr: 'BTT', offset: 21600, name: 'Thimphu' } },
  },
  BO: {
    name: 'Bolivia',
    timezones: { 'America/La_Paz': { abbr: 'BOT', offset: -14400, name: 'La Paz' } },
  },
  BA: {
    name: 'Bosnia and Herzegovina',
    timezones: { 'Europe/Sarajevo': { abbr: 'CET', offset: 3600, name: 'Sarajevo' } },
  },
  BW: {
    name: 'Botswana',
    timezones: { 'Africa/Gaborone': { abbr: 'CAT', offset: 7200, name: 'Gaborone' } },
  },
  BV: { name: 'Bouvet Island', timezones: [] },
  BR: {
    name: 'Brazil',
    timezones: {
      'America/Araguaina': { abbr: 'BRT', offset: -10800, name: 'Araguaina' },
      'America/Bahia': { abbr: 'BRT', offset: -10800, name: 'Bahia' },
      'America/Belem': { abbr: 'BRT', offset: -10800, name: 'Belem' },
      'America/Boa_Vista': { abbr: 'AMT', offset: -14400, name: 'Boa Vista' },
      'America/Campo_Grande': { abbr: 'AMST', offset: -10800, name: 'Campo Grande' },
      'America/Cuiaba': { abbr: 'AMST', offset: -10800, name: 'Cuiaba' },
      'America/Eirunepe': { abbr: 'AMT', offset: -14400, name: 'Eirunepe' },
      'America/Fortaleza': { abbr: 'BRT', offset: -10800, name: 'Fortaleza' },
      'America/Maceio': { abbr: 'BRT', offset: -10800, name: 'Maceio' },
      'America/Manaus': { abbr: 'AMT', offset: -14400, name: 'Manaus' },
      'America/Noronha': { abbr: 'FNT', offset: -7200, name: 'Noronha' },
      'America/Porto_Velho': { abbr: 'AMT', offset: -14400, name: 'Porto Velho' },
      'America/Recife': { abbr: 'BRT', offset: -10800, name: 'Recife' },
      'America/Rio_Branco': { abbr: 'AMT', offset: -14400, name: 'Rio Branco' },
      'America/Santarem': { abbr: 'BRT', offset: -10800, name: 'Santarem' },
      'America/Sao_Paulo': { abbr: 'BRST', offset: -7200, name: 'Sao Paulo' },
    },
  },
  IO: {
    name: 'British Indian Ocean Territory',
    timezones: { 'Indian/Chagos': { abbr: 'IOT', offset: 21600, name: 'Chagos' } },
  },
  BN: {
    name: 'Brunei Darussalam',
    timezones: { 'Asia/Brunei': { abbr: 'BNT', offset: 28800, name: 'Brunei' } },
  },
  BG: {
    name: 'Bulgaria',
    timezones: { 'Europe/Sofia': { abbr: 'EET', offset: 7200, name: 'Sofia' } },
  },
  BF: {
    name: 'Burkina Faso',
    timezones: { 'Africa/Ouagadougou': { abbr: 'GMT', offset: 0, name: 'Ouagadougou' } },
  },
  BI: {
    name: 'Burundi',
    timezones: { 'Africa/Bujumbura': { abbr: 'CAT', offset: 7200, name: 'Bujumbura' } },
  },
  KH: {
    name: 'Cambodia',
    timezones: { 'Asia/Phnom_Penh': { abbr: 'ICT', offset: 25200, name: 'Phnom Penh' } },
  },
  CM: {
    name: 'Cameroon',
    timezones: { 'Africa/Douala': { abbr: 'WAT', offset: 3600, name: 'Douala' } },
  },
  CA: {
    name: 'Canada',
    timezones: {
      'America/Atikokan': { abbr: 'EST', offset: -18000, name: 'Atikokan' },
      'America/Blanc-Sablon': { abbr: 'AST', offset: -14400, name: 'Blanc-Sablon' },
      'America/Cambridge_Bay': { abbr: 'MST', offset: -25200, name: 'Cambridge Bay' },
      'America/Dawson': { abbr: 'PST', offset: -28800, name: 'Dawson' },
      'America/Dawson_Creek': { abbr: 'MST', offset: -25200, name: 'Dawson Creek' },
      'America/Edmonton': { abbr: 'MST', offset: -25200, name: 'Edmonton' },
      'America/Glace_Bay': { abbr: 'AST', offset: -14400, name: 'Glace Bay' },
      'America/Goose_Bay': { abbr: 'AST', offset: -14400, name: 'Goose Bay' },
      'America/Halifax': { abbr: 'AST', offset: -14400, name: 'Halifax' },
      'America/Inuvik': { abbr: 'MST', offset: -25200, name: 'Inuvik' },
      'America/Iqaluit': { abbr: 'EST', offset: -18000, name: 'Iqaluit' },
      'America/Moncton': { abbr: 'AST', offset: -14400, name: 'Moncton' },
      'America/Montreal': { abbr: 'EST', offset: -18000, name: 'Montreal' },
      'America/Nipigon': { abbr: 'EST', offset: -18000, name: 'Nipigon' },
      'America/Pangnirtung': { abbr: 'EST', offset: -18000, name: 'Pangnirtung' },
      'America/Rainy_River': { abbr: 'CST', offset: -21600, name: 'Rainy River' },
      'America/Rankin_Inlet': { abbr: 'CST', offset: -21600, name: 'Rankin Inlet' },
      'America/Regina': { abbr: 'CST', offset: -21600, name: 'Regina' },
      'America/Resolute': { abbr: 'EST', offset: -18000, name: 'Resolute' },
      'America/St_Johns': { abbr: 'NST', offset: -12600, name: 'St Johns' },
      'America/Swift_Current': { abbr: 'CST', offset: -21600, name: 'Swift Current' },
      'America/Thunder_Bay': { abbr: 'EST', offset: -18000, name: 'Thunder Bay' },
      'America/Toronto': { abbr: 'EST', offset: -18000, name: 'Toronto' },
      'America/Vancouver': { abbr: 'PST', offset: -28800, name: 'Vancouver' },
      'America/Whitehorse': { abbr: 'PST', offset: -28800, name: 'Whitehorse' },
      'America/Winnipeg': { abbr: 'CST', offset: -21600, name: 'Winnipeg' },
      'America/Yellowknife': { abbr: 'MST', offset: -25200, name: 'Yellowknife' },
    },
  },
  CV: {
    name: 'Cape Verde',
    timezones: { 'Atlantic/Cape_Verde': { abbr: 'CVT', offset: -3600, name: 'Cape Verde' } },
  },
  KY: {
    name: 'Cayman Islands',
    timezones: { 'America/Cayman': { abbr: 'EST', offset: -18000, name: 'Cayman' } },
  },
  CF: {
    name: 'Central African Republic',
    timezones: { 'Africa/Bangui': { abbr: 'WAT', offset: 3600, name: 'Bangui' } },
  },
  TD: {
    name: 'Chad',
    timezones: { 'Africa/Ndjamena': { abbr: 'WAT', offset: 3600, name: 'Ndjamena' } },
  },
  CL: {
    name: 'Chile',
    timezones: {
      'America/Santiago': { abbr: 'CLST', offset: -10800, name: 'Santiago' },
      'Pacific/Easter': { abbr: 'EASST', offset: -18000, name: 'Easter' },
    },
  },
  CN: {
    name: 'China',
    timezones: {
      'Asia/Chongqing': { abbr: 'CST', offset: 28800, name: 'Chongqing' },
      'Asia/Harbin': { abbr: 'CST', offset: 28800, name: 'Harbin' },
      'Asia/Kashgar': { abbr: 'CST', offset: 28800, name: 'Kashgar' },
      'Asia/Shanghai': { abbr: 'CST', offset: 28800, name: 'Shanghai' },
      'Asia/Urumqi': { abbr: 'CST', offset: 28800, name: 'Urumqi' },
    },
  },
  CX: {
    name: 'Christmas Island',
    timezones: { 'Indian/Christmas': { abbr: 'CXT', offset: 25200, name: 'Christmas' } },
  },
  CC: {
    name: 'Cocos (Keeling) Islands',
    timezones: { 'Indian/Cocos': { abbr: 'CCT', offset: 23400, name: 'Cocos' } },
  },
  CO: {
    name: 'Colombia',
    timezones: { 'America/Bogota': { abbr: 'COT', offset: -18000, name: 'Bogota' } },
  },
  KM: {
    name: 'Comoros',
    timezones: { 'Indian/Comoro': { abbr: 'EAT', offset: 10800, name: 'Comoro' } },
  },
  CG: {
    name: 'Congo',
    timezones: { 'Africa/Brazzaville': { abbr: 'WAT', offset: 3600, name: 'Brazzaville' } },
  },
  CD: {
    name: 'Congo, the Democratic Republic of the',
    timezones: {
      'Africa/Kinshasa': { abbr: 'WAT', offset: 3600, name: 'Kinshasa' },
      'Africa/Lubumbashi': { abbr: 'CAT', offset: 7200, name: 'Lubumbashi' },
    },
  },
  CK: {
    name: 'Cook Islands',
    timezones: { 'Pacific/Rarotonga': { abbr: 'CKT', offset: -36000, name: 'Rarotonga' } },
  },
  CR: {
    name: 'Costa Rica',
    timezones: { 'America/Costa_Rica': { abbr: 'CST', offset: -21600, name: 'Costa Rica' } },
  },
  CI: {
    name: "Cote D'Ivoire",
    timezones: { 'Africa/Abidjan': { abbr: 'GMT', offset: 0, name: 'Abidjan' } },
  },
  HR: {
    name: 'Croatia',
    timezones: { 'Europe/Zagreb': { abbr: 'CET', offset: 3600, name: 'Zagreb' } },
  },
  CU: {
    name: 'Cuba',
    timezones: { 'America/Havana': { abbr: 'CST', offset: -18000, name: 'Havana' } },
  },
  CY: {
    name: 'Cyprus',
    timezones: { 'Asia/Nicosia': { abbr: 'EET', offset: 7200, name: 'Nicosia' } },
  },
  CZ: {
    name: 'Czech Republic',
    timezones: { 'Europe/Prague': { abbr: 'CET', offset: 3600, name: 'Prague' } },
  },
  DK: {
    name: 'Denmark',
    timezones: { 'Europe/Copenhagen': { abbr: 'CET', offset: 3600, name: 'Copenhagen' } },
  },
  DJ: {
    name: 'Djibouti',
    timezones: { 'Africa/Djibouti': { abbr: 'EAT', offset: 10800, name: 'Djibouti' } },
  },
  DM: {
    name: 'Dominica',
    timezones: { 'America/Dominica': { abbr: 'AST', offset: -14400, name: 'Dominica' } },
  },
  DO: {
    name: 'Dominican Republic',
    timezones: {
      'America/Santo_Domingo': { abbr: 'AST', offset: -14400, name: 'Santo Domingo' },
    },
  },
  EC: {
    name: 'Ecuador',
    timezones: {
      'America/Guayaquil': { abbr: 'ECT', offset: -18000, name: 'Guayaquil' },
      'Pacific/Galapagos': { abbr: 'GALT', offset: -21600, name: 'Galapagos' },
    },
  },
  EG: {
    name: 'Egypt',
    timezones: { 'Africa/Cairo': { abbr: 'EET', offset: 7200, name: 'Cairo' } },
  },
  SV: {
    name: 'El Salvador',
    timezones: { 'America/El_Salvador': { abbr: 'CST', offset: -21600, name: 'El Salvador' } },
  },
  GQ: {
    name: 'Equatorial Guinea',
    timezones: { 'Africa/Malabo': { abbr: 'WAT', offset: 3600, name: 'Malabo' } },
  },
  ER: {
    name: 'Eritrea',
    timezones: { 'Africa/Asmara': { abbr: 'EAT', offset: 10800, name: 'Asmara' } },
  },
  EE: {
    name: 'Estonia',
    timezones: { 'Europe/Tallinn': { abbr: 'EET', offset: 7200, name: 'Tallinn' } },
  },
  ET: {
    name: 'Ethiopia',
    timezones: { 'Africa/Addis_Ababa': { abbr: 'EAT', offset: 10800, name: 'Addis Ababa' } },
  },
  FK: {
    name: 'Falkland Islands (Malvinas)',
    timezones: { 'Atlantic/Stanley': { abbr: 'FKST', offset: -10800, name: 'Stanley' } },
  },
  FO: {
    name: 'Faroe Islands',
    timezones: { 'Atlantic/Faroe': { abbr: 'WET', offset: 0, name: 'Faroe' } },
  },
  FJ: {
    name: 'Fiji',
    timezones: { 'Pacific/Fiji': { abbr: 'FJT', offset: 43200, name: 'Fiji' } },
  },
  FI: {
    name: 'Finland',
    timezones: { 'Europe/Helsinki': { abbr: 'EET', offset: 7200, name: 'Helsinki' } },
  },
  FR: {
    name: 'France',
    timezones: { 'Europe/Paris': { abbr: 'CET', offset: 3600, name: 'Paris' } },
  },
  GF: {
    name: 'French Guiana',
    timezones: { 'America/Cayenne': { abbr: 'GFT', offset: -10800, name: 'Cayenne' } },
  },
  PF: {
    name: 'French Polynesia',
    timezones: {
      'Pacific/Gambier': { abbr: 'GAMT', offset: -32400, name: 'Gambier' },
      'Pacific/Marquesas': { abbr: 'MART', offset: -34200, name: 'Marquesas' },
      'Pacific/Tahiti': { abbr: 'TAHT', offset: -36000, name: 'Tahiti' },
    },
  },
  TF: {
    name: 'French Southern Territories',
    timezones: { 'Indian/Kerguelen': { abbr: 'TFT', offset: 18000, name: 'Kerguelen' } },
  },
  GA: {
    name: 'Gabon',
    timezones: { 'Africa/Libreville': { abbr: 'WAT', offset: 3600, name: 'Libreville' } },
  },
  GM: {
    name: 'Gambia',
    timezones: { 'Africa/Banjul': { abbr: 'GMT', offset: 0, name: 'Banjul' } },
  },
  GE: {
    name: 'Georgia',
    timezones: { 'Asia/Tbilisi': { abbr: 'GET', offset: 14400, name: 'Tbilisi' } },
  },
  DE: {
    name: 'Germany',
    timezones: { 'Europe/Berlin': { abbr: 'CET', offset: 3600, name: 'Berlin' } },
  },
  GH: { name: 'Ghana', timezones: { 'Africa/Accra': { abbr: 'GMT', offset: 0, name: 'Accra' } } },
  GI: {
    name: 'Gibraltar',
    timezones: { 'Europe/Gibraltar': { abbr: 'CET', offset: 3600, name: 'Gibraltar' } },
  },
  GR: {
    name: 'Greece',
    timezones: { 'Europe/Athens': { abbr: 'EET', offset: 7200, name: 'Athens' } },
  },
  GL: {
    name: 'Greenland',
    timezones: {
      'America/Danmarkshavn': { abbr: 'GMT', offset: 0, name: 'Danmarkshavn' },
      'America/Godthab': { abbr: 'WGT', offset: -10800, name: 'Godthab' },
      'America/Scoresbysund': { abbr: 'EGT', offset: -3600, name: 'Scoresbysund' },
      'America/Thule': { abbr: 'AST', offset: -14400, name: 'Thule' },
    },
  },
  GD: {
    name: 'Grenada',
    timezones: { 'America/Grenada': { abbr: 'AST', offset: -14400, name: 'Grenada' } },
  },
  GP: {
    name: 'Guadeloupe',
    timezones: { 'America/Guadeloupe': { abbr: 'AST', offset: -14400, name: 'Guadeloupe' } },
  },
  GU: {
    name: 'Guam',
    timezones: { 'Pacific/Guam': { abbr: 'ChST', offset: 36000, name: 'Guam' } },
  },
  GT: {
    name: 'Guatemala',
    timezones: { 'America/Guatemala': { abbr: 'CST', offset: -21600, name: 'Guatemala' } },
  },
  GN: {
    name: 'Guinea',
    timezones: { 'Africa/Conakry': { abbr: 'GMT', offset: 0, name: 'Conakry' } },
  },
  GW: {
    name: 'Guinea-Bissau',
    timezones: { 'Africa/Bissau': { abbr: 'GMT', offset: 0, name: 'Bissau' } },
  },
  GY: {
    name: 'Guyana',
    timezones: { 'America/Guyana': { abbr: 'GYT', offset: -14400, name: 'Guyana' } },
  },
  HT: {
    name: 'Haiti',
    timezones: {
      'America/Port-au-Prince': { abbr: 'EST', offset: -18000, name: 'Port-au-Prince' },
    },
  },
  HM: { name: 'Heard Island and Mcdonald Islands', timezones: [] },
  VA: {
    name: 'Holy See (Vatican City State)',
    timezones: { 'Europe/Vatican': { abbr: 'CET', offset: 3600, name: 'Vatican' } },
  },
  HN: {
    name: 'Honduras',
    timezones: { 'America/Tegucigalpa': { abbr: 'CST', offset: -21600, name: 'Tegucigalpa' } },
  },
  HK: {
    name: 'Hong Kong',
    timezones: { 'Asia/Hong_Kong': { abbr: 'HKT', offset: 28800, name: 'Hong Kong' } },
  },
  HU: {
    name: 'Hungary',
    timezones: { 'Europe/Budapest': { abbr: 'CET', offset: 3600, name: 'Budapest' } },
  },
  IS: {
    name: 'Iceland',
    timezones: { 'Atlantic/Reykjavik': { abbr: 'GMT', offset: 0, name: 'Reykjavik' } },
  },
  IN: {
    name: 'India',
    timezones: { 'Asia/Kolkata': { abbr: 'IST', offset: 19800, name: 'Kolkata' } },
  },
  ID: {
    name: 'Indonesia',
    timezones: {
      'Asia/Jakarta': { abbr: 'WIT', offset: 25200, name: 'Jakarta' },
      'Asia/Jayapura': { abbr: 'EIT', offset: 32400, name: 'Jayapura' },
      'Asia/Makassar': { abbr: 'CIT', offset: 28800, name: 'Makassar' },
      'Asia/Pontianak': { abbr: 'WIT', offset: 25200, name: 'Pontianak' },
    },
  },
  IR: {
    name: 'Iran, Islamic Republic of',
    timezones: { 'Asia/Tehran': { abbr: 'IRST', offset: 12600, name: 'Tehran' } },
  },
  IQ: {
    name: 'Iraq',
    timezones: { 'Asia/Baghdad': { abbr: 'AST', offset: 10800, name: 'Baghdad' } },
  },
  IE: {
    name: 'Ireland',
    timezones: { 'Europe/Dublin': { abbr: 'GMT', offset: 0, name: 'Dublin' } },
  },
  IL: {
    name: 'Israel',
    timezones: { 'Asia/Jerusalem': { abbr: 'IST', offset: 7200, name: 'Jerusalem' } },
  },
  IT: {
    name: 'Italy',
    timezones: { 'Europe/Rome': { abbr: 'CET', offset: 3600, name: 'Rome' } },
  },
  JM: {
    name: 'Jamaica',
    timezones: { 'America/Jamaica': { abbr: 'EST', offset: -18000, name: 'Jamaica' } },
  },
  JP: {
    name: 'Japan',
    timezones: { 'Asia/Tokyo': { abbr: 'JST', offset: 32400, name: 'Tokyo' } },
  },
  JO: {
    name: 'Jordan',
    timezones: { 'Asia/Amman': { abbr: 'EET', offset: 7200, name: 'Amman' } },
  },
  KZ: {
    name: 'Kazakhstan',
    timezones: {
      'Asia/Almaty': { abbr: 'ALMT', offset: 21600, name: 'Almaty' },
      'Asia/Aqtau': { abbr: 'AQTT', offset: 18000, name: 'Aqtau' },
      'Asia/Aqtobe': { abbr: 'AQTT', offset: 18000, name: 'Aqtobe' },
      'Asia/Oral': { abbr: 'ORAT', offset: 18000, name: 'Oral' },
      'Asia/Qyzylorda': { abbr: 'QYZT', offset: 21600, name: 'Qyzylorda' },
    },
  },
  KE: {
    name: 'Kenya',
    timezones: { 'Africa/Nairobi': { abbr: 'EAT', offset: 10800, name: 'Nairobi' } },
  },
  KI: {
    name: 'Kiribati',
    timezones: {
      'Pacific/Enderbury': { abbr: 'PHOT', offset: 46800, name: 'Enderbury' },
      'Pacific/Kiritimati': { abbr: 'LINT', offset: 50400, name: 'Kiritimati' },
      'Pacific/Tarawa': { abbr: 'GILT', offset: 43200, name: 'Tarawa' },
    },
  },
  KP: {
    name: "Korea, Democratic People's Republic of",
    timezones: { 'Asia/Pyongyang': { abbr: 'KST', offset: 32400, name: 'Pyongyang' } },
  },
  KR: {
    name: 'Korea, Republic of',
    timezones: { 'Asia/Seoul': { abbr: 'KST', offset: 32400, name: 'Seoul' } },
  },
  KW: {
    name: 'Kuwait',
    timezones: { 'Asia/Kuwait': { abbr: 'AST', offset: 10800, name: 'Kuwait' } },
  },
  KG: {
    name: 'Kyrgyzstan',
    timezones: { 'Asia/Bishkek': { abbr: 'KGT', offset: 21600, name: 'Bishkek' } },
  },
  LA: {
    name: "Lao People's Democratic Republic",
    timezones: { 'Asia/Vientiane': { abbr: 'ICT', offset: 25200, name: 'Vientiane' } },
  },
  LV: {
    name: 'Latvia',
    timezones: { 'Europe/Riga': { abbr: 'EET', offset: 7200, name: 'Riga' } },
  },
  LB: {
    name: 'Lebanon',
    timezones: { 'Asia/Beirut': { abbr: 'EET', offset: 7200, name: 'Beirut' } },
  },
  LS: {
    name: 'Lesotho',
    timezones: { 'Africa/Maseru': { abbr: 'SAST', offset: 7200, name: 'Maseru' } },
  },
  LR: {
    name: 'Liberia',
    timezones: { 'Africa/Monrovia': { abbr: 'GMT', offset: 0, name: 'Monrovia' } },
  },
  LY: {
    name: 'Libyan Arab Jamahiriya',
    timezones: { 'Africa/Tripoli': { abbr: 'EET', offset: 7200, name: 'Tripoli' } },
  },
  LI: {
    name: 'Liechtenstein',
    timezones: { 'Europe/Vaduz': { abbr: 'CET', offset: 3600, name: 'Vaduz' } },
  },
  LT: {
    name: 'Lithuania',
    timezones: { 'Europe/Vilnius': { abbr: 'EET', offset: 7200, name: 'Vilnius' } },
  },
  LU: {
    name: 'Luxembourg',
    timezones: { 'Europe/Luxembourg': { abbr: 'CET', offset: 3600, name: 'Luxembourg' } },
  },
  MO: {
    name: 'Macao',
    timezones: { 'Asia/Macau': { abbr: 'CST', offset: 28800, name: 'Macau' } },
  },
  MK: {
    name: 'Macedonia, the Former Yugoslav Republic of',
    timezones: { 'Europe/Skopje': { abbr: 'CET', offset: 3600, name: 'Skopje' } },
  },
  MG: {
    name: 'Madagascar',
    timezones: { 'Indian/Antananarivo': { abbr: 'EAT', offset: 10800, name: 'Antananarivo' } },
  },
  MW: {
    name: 'Malawi',
    timezones: { 'Africa/Blantyre': { abbr: 'CAT', offset: 7200, name: 'Blantyre' } },
  },
  MY: {
    name: 'Malaysia',
    timezones: {
      'Asia/Kuala_Lumpur': { abbr: 'MYT', offset: 28800, name: 'Kuala Lumpur' },
      'Asia/Kuching': { abbr: 'MYT', offset: 28800, name: 'Kuching' },
    },
  },
  MV: {
    name: 'Maldives',
    timezones: { 'Indian/Maldives': { abbr: 'MVT', offset: 18000, name: 'Maldives' } },
  },
  ML: {
    name: 'Mali',
    timezones: { 'Africa/Bamako': { abbr: 'GMT', offset: 0, name: 'Bamako' } },
  },
  MT: {
    name: 'Malta',
    timezones: { 'Europe/Malta': { abbr: 'CET', offset: 3600, name: 'Malta' } },
  },
  MH: {
    name: 'Marshall Islands',
    timezones: {
      'Pacific/Kwajalein': { abbr: 'MHT', offset: 43200, name: 'Kwajalein' },
      'Pacific/Majuro': { abbr: 'MHT', offset: 43200, name: 'Majuro' },
    },
  },
  MQ: {
    name: 'Martinique',
    timezones: { 'America/Martinique': { abbr: 'AST', offset: -14400, name: 'Martinique' } },
  },
  MR: {
    name: 'Mauritania',
    timezones: { 'Africa/Nouakchott': { abbr: 'GMT', offset: 0, name: 'Nouakchott' } },
  },
  MU: {
    name: 'Mauritius',
    timezones: { 'Indian/Mauritius': { abbr: 'MUT', offset: 14400, name: 'Mauritius' } },
  },
  YT: {
    name: 'Mayotte',
    timezones: { 'Indian/Mayotte': { abbr: 'EAT', offset: 10800, name: 'Mayotte' } },
  },
  MX: {
    name: 'Mexico',
    timezones: {
      'America/Bahia_Banderas': { abbr: 'CST', offset: -21600, name: 'Bahia Banderas' },
      'America/Cancun': { abbr: 'CST', offset: -21600, name: 'Cancun' },
      'America/Chihuahua': { abbr: 'MST', offset: -25200, name: 'Chihuahua' },
      'America/Hermosillo': { abbr: 'MST', offset: -25200, name: 'Hermosillo' },
      'America/Matamoros': { abbr: 'CST', offset: -21600, name: 'Matamoros' },
      'America/Mazatlan': { abbr: 'MST', offset: -25200, name: 'Mazatlan' },
      'America/Merida': { abbr: 'CST', offset: -21600, name: 'Merida' },
      'America/Mexico_City': { abbr: 'CST', offset: -21600, name: 'Mexico City' },
      'America/Monterrey': { abbr: 'CST', offset: -21600, name: 'Monterrey' },
      'America/Ojinaga': { abbr: 'MST', offset: -25200, name: 'Ojinaga' },
      'America/Santa_Isabel': { abbr: 'PST', offset: -28800, name: 'Santa Isabel' },
      'America/Tijuana': { abbr: 'PST', offset: -28800, name: 'Tijuana' },
    },
  },
  FM: {
    name: 'Micronesia, Federated States of',
    timezones: {
      'Pacific/Chuuk': { abbr: 'CHUT', offset: 36000, name: 'Chuuk' },
      'Pacific/Kosrae': { abbr: 'KOST', offset: 39600, name: 'Kosrae' },
      'Pacific/Pohnpei': { abbr: 'PONT', offset: 39600, name: 'Pohnpei' },
    },
  },
  MD: {
    name: 'Moldova, Republic of',
    timezones: { 'Europe/Chisinau': { abbr: 'EET', offset: 7200, name: 'Chisinau' } },
  },
  MC: {
    name: 'Monaco',
    timezones: { 'Europe/Monaco': { abbr: 'CET', offset: 3600, name: 'Monaco' } },
  },
  MN: {
    name: 'Mongolia',
    timezones: {
      'Asia/Choibalsan': { abbr: 'CHOT', offset: 28800, name: 'Choibalsan' },
      'Asia/Hovd': { abbr: 'HOVT', offset: 25200, name: 'Hovd' },
      'Asia/Ulaanbaatar': { abbr: 'ULAT', offset: 28800, name: 'Ulaanbaatar' },
    },
  },
  MS: {
    name: 'Montserrat',
    timezones: { 'America/Montserrat': { abbr: 'AST', offset: -14400, name: 'Montserrat' } },
  },
  MA: {
    name: 'Morocco',
    timezones: { 'Africa/Casablanca': { abbr: 'WET', offset: 0, name: 'Casablanca' } },
  },
  MZ: {
    name: 'Mozambique',
    timezones: { 'Africa/Maputo': { abbr: 'CAT', offset: 7200, name: 'Maputo' } },
  },
  MM: {
    name: 'Myanmar',
    timezones: { 'Asia/Rangoon': { abbr: 'MMT', offset: 23400, name: 'Rangoon' } },
  },
  NA: {
    name: 'Namibia',
    timezones: { 'Africa/Windhoek': { abbr: 'WAST', offset: 7200, name: 'Windhoek' } },
  },
  NR: {
    name: 'Nauru',
    timezones: { 'Pacific/Nauru': { abbr: 'NRT', offset: 43200, name: 'Nauru' } },
  },
  NP: {
    name: 'Nepal',
    timezones: { 'Asia/Kathmandu': { abbr: 'NPT', offset: 20700, name: 'Kathmandu' } },
  },
  NL: {
    name: 'Netherlands',
    timezones: { 'Europe/Amsterdam': { abbr: 'CET', offset: 3600, name: 'Amsterdam' } },
  },
  AN: {
    name: 'Netherlands Antilles',
    timezones: { 'America/Curacao': { abbr: 'AST', offset: -14400, name: 'Curacao' } },
  },
  NC: {
    name: 'New Caledonia',
    timezones: { 'Pacific/Noumea': { abbr: 'NCT', offset: 39600, name: 'Noumea' } },
  },
  NZ: {
    name: 'New Zealand',
    timezones: {
      'Pacific/Auckland': { abbr: 'NZDT', offset: 46800, name: 'Auckland' },
      'Pacific/Chatham': { abbr: 'CHADT', offset: 49500, name: 'Chatham' },
    },
  },
  NI: {
    name: 'Nicaragua',
    timezones: { 'America/Managua': { abbr: 'CST', offset: -21600, name: 'Managua' } },
  },
  NE: {
    name: 'Niger',
    timezones: { 'Africa/Niamey': { abbr: 'WAT', offset: 3600, name: 'Niamey' } },
  },
  NG: {
    name: 'Nigeria',
    timezones: { 'Africa/Lagos': { abbr: 'WAT', offset: 3600, name: 'Lagos' } },
  },
  NU: {
    name: 'Niue',
    timezones: { 'Pacific/Niue': { abbr: 'NUT', offset: -39600, name: 'Niue' } },
  },
  NF: {
    name: 'Norfolk Island',
    timezones: { 'Pacific/Norfolk': { abbr: 'NFT', offset: 41400, name: 'Norfolk' } },
  },
  MP: {
    name: 'Northern Mariana Islands',
    timezones: { 'Pacific/Saipan': { abbr: 'ChST', offset: 36000, name: 'Saipan' } },
  },
  NO: {
    name: 'Norway',
    timezones: { 'Europe/Oslo': { abbr: 'CET', offset: 3600, name: 'Oslo' } },
  },
  OM: {
    name: 'Oman',
    timezones: { 'Asia/Muscat': { abbr: 'GST', offset: 14400, name: 'Muscat' } },
  },
  PK: {
    name: 'Pakistan',
    timezones: { 'Asia/Karachi': { abbr: 'PKT', offset: 18000, name: 'Karachi' } },
  },
  PW: {
    name: 'Palau',
    timezones: { 'Pacific/Palau': { abbr: 'PWT', offset: 32400, name: 'Palau' } },
  },
  PS: {
    name: 'Palestinian Territory, Occupied',
    timezones: { 'Asia/Gaza': { abbr: 'EET', offset: 7200, name: 'Gaza' } },
  },
  PA: {
    name: 'Panama',
    timezones: { 'America/Panama': { abbr: 'EST', offset: -18000, name: 'Panama' } },
  },
  PG: {
    name: 'Papua New Guinea',
    timezones: { 'Pacific/Port_Moresby': { abbr: 'PGT', offset: 36000, name: 'Port Moresby' } },
  },
  PY: {
    name: 'Paraguay',
    timezones: { 'America/Asuncion': { abbr: 'PYST', offset: -10800, name: 'Asuncion' } },
  },
  PE: {
    name: 'Peru',
    timezones: { 'America/Lima': { abbr: 'PET', offset: -18000, name: 'Lima' } },
  },
  PH: {
    name: 'Philippines',
    timezones: { 'Asia/Manila': { abbr: 'PHT', offset: 28800, name: 'Manila' } },
  },
  PN: {
    name: 'Pitcairn',
    timezones: { 'Pacific/Pitcairn': { abbr: 'PST', offset: -28800, name: 'Pitcairn' } },
  },
  PL: {
    name: 'Poland',
    timezones: { 'Europe/Warsaw': { abbr: 'CET', offset: 3600, name: 'Warsaw' } },
  },
  PT: {
    name: 'Portugal',
    timezones: {
      'Atlantic/Azores': { abbr: 'AZOT', offset: -3600, name: 'Azores' },
      'Atlantic/Madeira': { abbr: 'WET', offset: 0, name: 'Madeira' },
      'Europe/Lisbon': { abbr: 'WET', offset: 0, name: 'Lisbon' },
    },
  },
  PR: {
    name: 'Puerto Rico',
    timezones: { 'America/Puerto_Rico': { abbr: 'AST', offset: -14400, name: 'Puerto Rico' } },
  },
  QA: {
    name: 'Qatar',
    timezones: { 'Asia/Qatar': { abbr: 'AST', offset: 10800, name: 'Qatar' } },
  },
  RE: {
    name: 'Reunion',
    timezones: { 'Indian/Reunion': { abbr: 'RET', offset: 14400, name: 'Reunion' } },
  },
  RO: {
    name: 'Romania',
    timezones: { 'Europe/Bucharest': { abbr: 'EET', offset: 7200, name: 'Bucharest' } },
  },
  RU: {
    name: 'Russian Federation',
    timezones: {
      'Asia/Anadyr': { abbr: 'ANAT', offset: 39600, name: 'Anadyr' },
      'Asia/Irkutsk': { abbr: 'IRKT', offset: 28800, name: 'Irkutsk' },
      'Asia/Kamchatka': { abbr: 'PETT', offset: 39600, name: 'Kamchatka' },
      'Asia/Krasnoyarsk': { abbr: 'KRAT', offset: 25200, name: 'Krasnoyarsk' },
      'Asia/Magadan': { abbr: 'MAGT', offset: 39600, name: 'Magadan' },
      'Asia/Novokuznetsk': { abbr: 'NOVT', offset: 21600, name: 'Novokuznetsk' },
      'Asia/Novosibirsk': { abbr: 'NOVT', offset: 21600, name: 'Novosibirsk' },
      'Asia/Omsk': { abbr: 'OMST', offset: 21600, name: 'Omsk' },
      'Asia/Sakhalin': { abbr: 'SAKT', offset: 36000, name: 'Sakhalin' },
      'Asia/Vladivostok': { abbr: 'VLAT', offset: 36000, name: 'Vladivostok' },
      'Asia/Yakutsk': { abbr: 'YAKT', offset: 32400, name: 'Yakutsk' },
      'Asia/Yekaterinburg': { abbr: 'YEKT', offset: 18000, name: 'Yekaterinburg' },
      'Europe/Kaliningrad': { abbr: 'EET', offset: 7200, name: 'Kaliningrad' },
      'Europe/Moscow': { abbr: 'MSK', offset: 10800, name: 'Moscow' },
      'Europe/Samara': { abbr: 'SAMT', offset: 10800, name: 'Samara' },
      'Europe/Volgograd': { abbr: 'VOLT', offset: 10800, name: 'Volgograd' },
    },
  },
  RW: {
    name: 'Rwanda',
    timezones: { 'Africa/Kigali': { abbr: 'CAT', offset: 7200, name: 'Kigali' } },
  },
  SH: {
    name: 'Saint Helena',
    timezones: { 'Atlantic/St_Helena': { abbr: 'GMT', offset: 0, name: 'St Helena' } },
  },
  KN: {
    name: 'Saint Kitts and Nevis',
    timezones: { 'America/St_Kitts': { abbr: 'AST', offset: -14400, name: 'St Kitts' } },
  },
  LC: {
    name: 'Saint Lucia',
    timezones: { 'America/St_Lucia': { abbr: 'AST', offset: -14400, name: 'St Lucia' } },
  },
  PM: {
    name: 'Saint Pierre and Miquelon',
    timezones: { 'America/Miquelon': { abbr: 'PMST', offset: -10800, name: 'Miquelon' } },
  },
  VC: {
    name: 'Saint Vincent and the Grenadines',
    timezones: { 'America/St_Vincent': { abbr: 'AST', offset: -14400, name: 'St Vincent' } },
  },
  WS: {
    name: 'Samoa',
    timezones: { 'Pacific/Apia': { abbr: 'WST', offset: -39600, name: 'Apia' } },
  },
  SM: {
    name: 'San Marino',
    timezones: { 'Europe/San_Marino': { abbr: 'CET', offset: 3600, name: 'San Marino' } },
  },
  ST: {
    name: 'Sao Tome and Principe',
    timezones: { 'Africa/Sao_Tome': { abbr: 'GMT', offset: 0, name: 'Sao Tome' } },
  },
  SA: {
    name: 'Saudi Arabia',
    timezones: { 'Asia/Riyadh': { abbr: 'AST', offset: 10800, name: 'Riyadh' } },
  },
  SN: {
    name: 'Senegal',
    timezones: { 'Africa/Dakar': { abbr: 'GMT', offset: 0, name: 'Dakar' } },
  },
  CS: { name: 'Serbia and Montenegro', timezones: [] },
  SC: {
    name: 'Seychelles',
    timezones: { 'Indian/Mahe': { abbr: 'SCT', offset: 14400, name: 'Mahe' } },
  },
  SL: {
    name: 'Sierra Leone',
    timezones: { 'Africa/Freetown': { abbr: 'GMT', offset: 0, name: 'Freetown' } },
  },
  SG: {
    name: 'Singapore',
    timezones: { 'Asia/Singapore': { abbr: 'SGT', offset: 28800, name: 'Singapore' } },
  },
  SK: {
    name: 'Slovakia',
    timezones: { 'Europe/Bratislava': { abbr: 'CET', offset: 3600, name: 'Bratislava' } },
  },
  SI: {
    name: 'Slovenia',
    timezones: { 'Europe/Ljubljana': { abbr: 'CET', offset: 3600, name: 'Ljubljana' } },
  },
  SB: {
    name: 'Solomon Islands',
    timezones: { 'Pacific/Guadalcanal': { abbr: 'SBT', offset: 39600, name: 'Guadalcanal' } },
  },
  SO: {
    name: 'Somalia',
    timezones: { 'Africa/Mogadishu': { abbr: 'EAT', offset: 10800, name: 'Mogadishu' } },
  },
  ZA: {
    name: 'South Africa',
    timezones: { 'Africa/Johannesburg': { abbr: 'SAST', offset: 7200, name: 'Johannesburg' } },
  },
  GS: {
    name: 'South Georgia and the South Sandwich Islands',
    timezones: {
      'Atlantic/South_Georgia': { abbr: 'GST', offset: -7200, name: 'South Georgia' },
    },
  },
  ES: {
    name: 'Spain',
    timezones: {
      'Africa/Ceuta': { abbr: 'CET', offset: 3600, name: 'Ceuta' },
      'Atlantic/Canary': { abbr: 'WET', offset: 0, name: 'Canary' },
      'Europe/Madrid': { abbr: 'CET', offset: 3600, name: 'Madrid' },
    },
  },
  LK: {
    name: 'Sri Lanka',
    timezones: { 'Asia/Colombo': { abbr: 'IST', offset: 19800, name: 'Colombo' } },
  },
  SD: {
    name: 'Sudan',
    timezones: { 'Africa/Khartoum': { abbr: 'EAT', offset: 10800, name: 'Khartoum' } },
  },
  SR: {
    name: 'Suriname',
    timezones: { 'America/Paramaribo': { abbr: 'SRT', offset: -10800, name: 'Paramaribo' } },
  },
  SJ: {
    name: 'Svalbard and Jan Mayen',
    timezones: { 'Arctic/Longyearbyen': { abbr: 'CET', offset: 3600, name: 'Longyearbyen' } },
  },
  SZ: {
    name: 'Swaziland',
    timezones: { 'Africa/Mbabane': { abbr: 'SAST', offset: 7200, name: 'Mbabane' } },
  },
  SE: {
    name: 'Sweden',
    timezones: { 'Europe/Stockholm': { abbr: 'CET', offset: 3600, name: 'Stockholm' } },
  },
  CH: {
    name: 'Switzerland',
    timezones: { 'Europe/Zurich': { abbr: 'CET', offset: 3600, name: 'Zurich' } },
  },
  SY: {
    name: 'Syrian Arab Republic',
    timezones: { 'Asia/Damascus': { abbr: 'EET', offset: 7200, name: 'Damascus' } },
  },
  TW: {
    name: 'Taiwan, Province of China',
    timezones: { 'Asia/Taipei': { abbr: 'CST', offset: 28800, name: 'Taipei' } },
  },
  TJ: {
    name: 'Tajikistan',
    timezones: { 'Asia/Dushanbe': { abbr: 'TJT', offset: 18000, name: 'Dushanbe' } },
  },
  TZ: {
    name: 'Tanzania, United Republic of',
    timezones: { 'Africa/Dar_es_Salaam': { abbr: 'EAT', offset: 10800, name: 'Dar es Salaam' } },
  },
  TH: {
    name: 'Thailand',
    timezones: { 'Asia/Bangkok': { abbr: 'ICT', offset: 25200, name: 'Bangkok' } },
  },
  TL: {
    name: 'Timor-Leste',
    timezones: { 'Asia/Dili': { abbr: 'TLT', offset: 32400, name: 'Dili' } },
  },
  TG: { name: 'Togo', timezones: { 'Africa/Lome': { abbr: 'GMT', offset: 0, name: 'Lome' } } },
  TK: {
    name: 'Tokelau',
    timezones: { 'Pacific/Fakaofo': { abbr: 'TKT', offset: -36000, name: 'Fakaofo' } },
  },
  TO: {
    name: 'Tonga',
    timezones: { 'Pacific/Tongatapu': { abbr: 'TOT', offset: 46800, name: 'Tongatapu' } },
  },
  TT: {
    name: 'Trinidad and Tobago',
    timezones: {
      'America/Port_of_Spain': { abbr: 'AST', offset: -14400, name: 'Port of Spain' },
    },
  },
  TN: {
    name: 'Tunisia',
    timezones: { 'Africa/Tunis': { abbr: 'CET', offset: 3600, name: 'Tunis' } },
  },
  TR: {
    name: 'Turkey',
    timezones: { 'Europe/Istanbul': { abbr: 'EET', offset: 7200, name: 'Istanbul' } },
  },
  TM: {
    name: 'Turkmenistan',
    timezones: { 'Asia/Ashgabat': { abbr: 'TMT', offset: 18000, name: 'Ashgabat' } },
  },
  TC: {
    name: 'Turks and Caicos Islands',
    timezones: { 'America/Grand_Turk': { abbr: 'EST', offset: -18000, name: 'Grand Turk' } },
  },
  TV: {
    name: 'Tuvalu',
    timezones: { 'Pacific/Funafuti': { abbr: 'TVT', offset: 43200, name: 'Funafuti' } },
  },
  UG: {
    name: 'Uganda',
    timezones: { 'Africa/Kampala': { abbr: 'EAT', offset: 10800, name: 'Kampala' } },
  },
  UA: {
    name: 'Ukraine',
    timezones: {
      'Europe/Kiev': { abbr: 'EET', offset: 7200, name: 'Kiev' },
      'Europe/Simferopol': { abbr: 'EET', offset: 7200, name: 'Simferopol' },
      'Europe/Uzhgorod': { abbr: 'EET', offset: 7200, name: 'Uzhgorod' },
      'Europe/Zaporozhye': { abbr: 'EET', offset: 7200, name: 'Zaporozhye' },
    },
  },
  AE: {
    name: 'United Arab Emirates',
    timezones: { 'Asia/Dubai': { abbr: 'GST', offset: 14400, name: 'Dubai' } },
  },
  GB: {
    name: 'United Kingdom',
    timezones: { 'Europe/London': { abbr: 'GMT', offset: 0, name: 'London' } },
  },
  US: {
    name: 'United States',
    timezones: {
      'America/Adak': { abbr: 'HAST', offset: -36000, name: 'Adak' },
      'America/Anchorage': { abbr: 'AKST', offset: -32400, name: 'Anchorage' },
      'America/Chicago': { abbr: 'CST', offset: -21600, name: 'Chicago' },
      'America/Denver': { abbr: 'MST', offset: -25200, name: 'Denver' },
      'America/Los_Angeles': { abbr: 'PST', offset: -28800, name: 'Los Angeles' },
      'America/New_York': { abbr: 'EST', offset: -18000, name: 'New York' },
      'America/Phoenix': { abbr: 'MST', offset: -25200, name: 'Phoenix' },
      'Pacific/Honolulu': { abbr: 'HST', offset: -36000, name: 'Honolulu' },
    },
  },
  UM: {
    name: 'United States Minor Outlying Islands',
    timezones: {
      'Pacific/Johnston': { abbr: 'HST', offset: -36000, name: 'Johnston' },
      'Pacific/Midway': { abbr: 'SST', offset: -39600, name: 'Midway' },
      'Pacific/Wake': { abbr: 'WAKT', offset: 43200, name: 'Wake' },
    },
  },
  UY: {
    name: 'Uruguay',
    timezones: { 'America/Montevideo': { abbr: 'UYST', offset: -7200, name: 'Montevideo' } },
  },
  UZ: {
    name: 'Uzbekistan',
    timezones: {
      'Asia/Samarkand': { abbr: 'UZT', offset: 18000, name: 'Samarkand' },
      'Asia/Tashkent': { abbr: 'UZT', offset: 18000, name: 'Tashkent' },
    },
  },
  VU: {
    name: 'Vanuatu',
    timezones: { 'Pacific/Efate': { abbr: 'VUT', offset: 39600, name: 'Efate' } },
  },
  VE: {
    name: 'Venezuela',
    timezones: { 'America/Caracas': { abbr: 'VET', offset: -16200, name: 'Caracas' } },
  },
  VN: {
    name: 'Viet Nam',
    timezones: { 'Asia/Ho_Chi_Minh': { abbr: 'ICT', offset: 25200, name: 'Ho Chi Minh' } },
  },
  VG: {
    name: 'Virgin Islands, British',
    timezones: { 'America/Tortola': { abbr: 'AST', offset: -14400, name: 'Tortola' } },
  },
  VI: {
    name: 'Virgin Islands, U.s.',
    timezones: { 'America/St_Thomas': { abbr: 'AST', offset: -14400, name: 'St Thomas' } },
  },
  WF: {
    name: 'Wallis and Futuna',
    timezones: { 'Pacific/Wallis': { abbr: 'WFT', offset: 43200, name: 'Wallis' } },
  },
  EH: {
    name: 'Western Sahara',
    timezones: { 'Africa/El_Aaiun': { abbr: 'WET', offset: 0, name: 'El Aaiun' } },
  },
  YE: { name: 'Yemen', timezones: { 'Asia/Aden': { abbr: 'AST', offset: 10800, name: 'Aden' } } },
  ZM: {
    name: 'Zambia',
    timezones: { 'Africa/Lusaka': { abbr: 'CAT', offset: 7200, name: 'Lusaka' } },
  },
  ZW: {
    name: 'Zimbabwe',
    timezones: { 'Africa/Harare': { abbr: 'CAT', offset: 7200, name: 'Harare' } },
  },
};

export const timezoneList: any = [
  {
    zone: 'Pacific/Midway',
    gmt: '(GMT-11:00)',
    name: 'Midway Island',
  },
  {
    zone: 'US/Samoa',
    gmt: '(GMT-11:00)',
    name: 'Samoa',
  },
  {
    zone: 'US/Hawaii',
    gmt: '(GMT-10:00)',
    name: 'Hawaii',
  },
  {
    zone: 'US/Alaska',
    gmt: '(GMT-09:00)',
    name: 'Alaska',
  },
  {
    zone: 'US/Pacific',
    gmt: '(GMT-08:00)',
    name: 'Pacific Time (US & Canada)',
  },
  {
    zone: 'America/Tijuana',
    gmt: '(GMT-08:00)',
    name: 'Tijuana',
  },
  {
    zone: 'US/Arizona',
    gmt: '(GMT-07:00)',
    name: 'Arizona',
  },
  {
    zone: 'US/Mountain',
    gmt: '(GMT-07:00)',
    name: 'Mountain Time (US & Canada)',
  },
  {
    zone: 'America/Chihuahua',
    gmt: '(GMT-07:00)',
    name: 'Chihuahua',
  },
  {
    zone: 'America/Mazatlan',
    gmt: '(GMT-07:00)',
    name: 'Mazatlan',
  },
  {
    zone: 'America/Mexico_City',
    gmt: '(GMT-06:00)',
    name: 'Mexico City',
  },
  {
    zone: 'America/Monterrey',
    gmt: '(GMT-06:00)',
    name: 'Monterrey',
  },
  {
    zone: 'Canada/Saskatchewan',
    gmt: '(GMT-06:00)',
    name: 'Saskatchewan',
  },
  {
    zone: 'US/Central',
    gmt: '(GMT-06:00)',
    name: 'Central Time (US & Canada)',
  },
  {
    zone: 'US/Eastern',
    gmt: '(GMT-05:00)',
    name: 'Eastern Time (US & Canada)',
  },
  {
    zone: 'US/East-Indiana',
    gmt: '(GMT-05:00)',
    name: 'Indiana (East)',
  },
  {
    zone: 'America/Bogota',
    gmt: '(GMT-05:00)',
    name: 'Bogota',
  },
  {
    zone: 'America/Lima',
    gmt: '(GMT-05:00)',
    name: 'Lima',
  },
  {
    zone: 'America/Caracas',
    gmt: '(GMT-04:30)',
    name: 'Caracas',
  },
  {
    zone: 'Canada/Atlantic',
    gmt: '(GMT-04:00)',
    name: 'Atlantic Time (Canada)',
  },
  {
    zone: 'America/La_Paz',
    gmt: '(GMT-04:00)',
    name: 'La_Paz',
  },
  {
    zone: 'America/Santiago',
    gmt: '(GMT-04:00)',
    name: 'Santiago',
  },
  {
    zone: 'Canada/Newfoundland',
    gmt: '(GMT-03:30)',
    name: 'Newfoundland',
  },
  {
    zone: 'America/Buenos_Aires',
    gmt: '(GMT-03:00)',
    name: 'Buenos Aires',
  },
  {
    zone: 'Greenland',
    gmt: '(GMT-03:00)',
    name: 'Greenland',
  },
  {
    zone: 'Atlantic/Stanley',
    gmt: '(GMT-02:00)',
    name: 'Stanley',
  },
  {
    zone: 'Atlantic/Azores',
    gmt: '(GMT-01:00)',
    name: 'Azores',
  },
  {
    zone: 'Atlantic/Cape_Verde',
    gmt: '(GMT-01:00)',
    name: 'Cape Verde Is.',
  },
  {
    zone: 'Africa/Casablanca',
    gmt: '(GMT)',
    name: 'Casablanca',
  },
  {
    zone: 'Europe/Dublin',
    gmt: '(GMT)',
    name: 'Dublin',
  },
  {
    zone: 'Europe/Lisbon',
    gmt: '(GMT)',
    name: 'Libson',
  },
  {
    zone: 'Europe/London',
    gmt: '(GMT)',
    name: 'London',
  },
  {
    zone: 'Africa/Monrovia',
    gmt: '(GMT)',
    name: 'Monrovia',
  },
  {
    zone: 'Europe/Amsterdam',
    gmt: '(GMT+01:00)',
    name: 'Amsterdam',
  },
  {
    zone: 'Europe/Belgrade',
    gmt: '(GMT+01:00)',
    name: 'Belgrade',
  },
  {
    zone: 'Europe/Berlin',
    gmt: '(GMT+01:00)',
    name: 'Berlin',
  },
  {
    zone: 'Europe/Bratislava',
    gmt: '(GMT+01:00)',
    name: 'Bratislava',
  },
  {
    zone: 'Europe/Brussels',
    gmt: '(GMT+01:00)',
    name: 'Brussels',
  },
  {
    zone: 'Europe/Budapest',
    gmt: '(GMT+01:00)',
    name: 'Budapest',
  },
  {
    zone: 'Europe/Copenhagen',
    gmt: '(GMT+01:00)',
    name: 'Copenhagen',
  },
  {
    zone: 'Europe/Ljubljana',
    gmt: '(GMT+01:00)',
    name: 'Ljubljana',
  },
  {
    zone: 'Europe/Madrid',
    gmt: '(GMT+01:00)',
    name: 'Madrid',
  },
  {
    zone: 'Europe/Paris',
    gmt: '(GMT+01:00)',
    name: 'Paris',
  },
  {
    zone: 'Europe/Prague',
    gmt: '(GMT+01:00)',
    name: 'Prague',
  },
  {
    zone: 'Europe/Rome',
    gmt: '(GMT+01:00)',
    name: 'Rome',
  },
  {
    zone: 'Europe/Sarajevo',
    gmt: '(GMT+01:00)',
    name: 'Sarajevo',
  },
  {
    zone: 'Europe/Skopje',
    gmt: '(GMT+01:00)',
    name: 'Skopje',
  },
  {
    zone: 'Europe/Stockholm',
    gmt: '(GMT+01:00)',
    name: 'Stockholm',
  },
  {
    zone: 'Europe/Vienna',
    gmt: '(GMT+01:00)',
    name: 'Vienna',
  },
  {
    zone: 'Europe/Warsaw',
    gmt: '(GMT+01:00)',
    name: 'Warsaw',
  },
  {
    zone: 'Europe/Zagreb',
    gmt: '(GMT+01:00)',
    name: 'Zagreb',
  },
  {
    zone: 'Europe/Athens',
    gmt: '(GMT+02:00)',
    name: 'Athens',
  },
  {
    zone: 'Europe/Bucharest',
    gmt: '(GMT+02:00)',
    name: 'Bucharest',
  },
  {
    zone: 'Africa/Cairo',
    gmt: '(GMT+02:00)',
    name: 'Cairo',
  },
  {
    zone: 'Africa/Harare',
    gmt: '(GMT+02:00)',
    name: 'Harere',
  },
  {
    zone: 'Europe/Helsinki',
    gmt: '(GMT+02:00)',
    name: 'Helsinki',
  },
  {
    zone: 'Europe/Istanbul',
    gmt: '(GMT+02:00)',
    name: 'Istanbul',
  },
  {
    zone: 'Asia/Jerusalem',
    gmt: '(GMT+02:00)',
    name: 'Jerusalem',
  },
  {
    zone: 'Europe/Kiev',
    gmt: '(GMT+02:00)',
    name: 'Kiev',
  },
  {
    zone: 'Europe/Minsk',
    gmt: '(GMT+02:00)',
    name: 'Minsk',
  },
  {
    zone: 'Europe/Riga',
    gmt: '(GMT+02:00)',
    name: 'Riga',
  },
  {
    zone: 'Europe/Sofia',
    gmt: '(GMT+02:00)',
    name: 'Sofia',
  },
  {
    zone: 'Europe/Tallinn',
    gmt: '(GMT+02:00)',
    name: 'Tallinn',
  },
  {
    zone: 'Europe/Vilnius',
    gmt: '(GMT+02:00)',
    name: 'Vilnius',
  },
  {
    zone: 'Asia/Baghdad',
    gmt: '(GMT+03:00)',
    name: 'Baghdad',
  },
  {
    zone: 'Asia/Kuwait',
    gmt: '(GMT+03:00)',
    name: 'Kuwait',
  },
  {
    zone: 'Africa/Nairobi',
    gmt: '(GMT+03:00)',
    name: 'Nairobi',
  },
  {
    zone: 'Asia/Riyadh',
    gmt: '(GMT+03:00)',
    name: 'Riyadh',
  },
  {
    zone: 'Asia/Tehran',
    gmt: '(GMT+03:30)',
    name: 'Tehran',
  },
  {
    zone: 'Europe/Moscow',
    gmt: '(GMT+04:00)',
    name: 'Moscow',
  },
  {
    zone: 'Asia/Baku',
    gmt: '(GMT+04:00)',
    name: 'Baku',
  },
  {
    zone: 'Europe/Volgograd',
    gmt: '(GMT+04:00)',
    name: 'Volgograd',
  },
  {
    zone: 'Asia/Muscat',
    gmt: '(GMT+04:00)',
    name: 'Muscat',
  },
  {
    zone: 'Asia/Tbilisi',
    gmt: '(GMT+04:00)',
    name: 'Tbilisi',
  },
  {
    zone: 'Asia/Yerevan',
    gmt: '(GMT+04:00)',
    name: 'Yerevan',
  },
  {
    zone: 'Asia/Kabul',
    gmt: '(GMT+04:30)',
    name: 'Kabul',
  },
  {
    zone: 'Asia/Karachi',
    gmt: '(GMT+05:00)',
    name: 'Karachi',
  },
  {
    zone: 'Asia/Tashkent',
    gmt: '(GMT+05:00)',
    name: 'Tashkent',
  },
  {
    zone: 'Asia/Kolkata',
    gmt: '(GMT+05:30)',
    name: 'Kolkata',
  },
  {
    zone: 'Asia/Kathmandu',
    gmt: '(GMT+05:45)',
    name: 'Kathmandu',
  },
  {
    zone: 'Asia/Yekaterinburg',
    gmt: '(GMT+06:00)',
    name: 'Yekaterinburg',
  },
  {
    zone: 'Asia/Almaty',
    gmt: '(GMT+06:00)',
    name: 'Almaty',
  },
  {
    zone: 'Asia/Dhaka',
    gmt: '(GMT+06:00)',
    name: 'Dhaka',
  },
  {
    zone: 'Asia/Novosibirsk',
    gmt: '(GMT+07:00)',
    name: 'Novosibirsk',
  },
  {
    zone: 'Asia/Bangkok',
    gmt: '(GMT+07:00)',
    name: 'Bangkok',
  },
  {
    zone: 'Asia/Jakarta',
    gmt: '(GMT+07:00)',
    name: 'Jakarta',
  },
  {
    zone: 'Asia/Krasnoyarsk',
    gmt: '(GMT+08:00)',
    name: 'Krasnoyarsk',
  },
  {
    zone: 'Asia/Chongqing',
    gmt: '(GMT+08:00)',
    name: 'Chongqing',
  },
  {
    zone: 'Asia/Hong_Kong',
    gmt: '(GMT+08:00)',
    name: 'Hong Kong',
  },
  {
    zone: 'Asia/Kuala_Lumpur',
    gmt: '(GMT+08:00)',
    name: 'Kuala Lumpur',
  },
  {
    zone: 'Australia/Perth',
    gmt: '(GMT+08:00)',
    name: 'Perth',
  },
  {
    zone: 'Asia/Singapore',
    gmt: '(GMT+08:00)',
    name: 'Singapore',
  },
  {
    zone: 'Asia/Taipei',
    gmt: '(GMT+08:00)',
    name: 'Taipei',
  },
  {
    zone: 'Asia/Ulaanbaatar',
    gmt: '(GMT+08:00)',
    name: 'Ulaan Bataar',
  },
  {
    zone: 'Asia/Urumqi',
    gmt: '(GMT+08:00)',
    name: 'Urumqi',
  },
  {
    zone: 'Asia/Irkutsk',
    gmt: '(GMT+09:00)',
    name: 'Irkutsk',
  },
  {
    zone: 'Asia/Seoul',
    gmt: '(GMT+09:00)',
    name: 'Seoul',
  },
  {
    zone: 'Asia/Tokyo',
    gmt: '(GMT+09:00)',
    name: 'Tokyo',
  },
  {
    zone: 'Australia/Adelaide',
    gmt: '(GMT+09:30)',
    name: 'Adelaide',
  },
  {
    zone: 'Australia/Darwin',
    gmt: '(GMT+09:30)',
    name: 'Darwin',
  },
  {
    zone: 'Asia/Yakutsk',
    gmt: '(GMT+10:00)',
    name: 'Yakutsk',
  },
  {
    zone: 'Australia/Brisbane',
    gmt: '(GMT+10:00)',
    name: 'Brisbane',
  },
  {
    zone: 'Australia/Canberra',
    gmt: '(GMT+10:00)',
    name: 'Canberra',
  },
  {
    zone: 'Pacific/Guam',
    gmt: '(GMT+10:00)',
    name: 'Guam',
  },
  {
    zone: 'Australia/Hobart',
    gmt: '(GMT+10:00)',
    name: 'Hobart',
  },
  {
    zone: 'Australia/Melbourne',
    gmt: '(GMT+10:00)',
    name: 'Melbourne',
  },
  {
    zone: 'Pacific/Port_Moresby',
    gmt: '(GMT+10:00)',
    name: 'Port Moresby',
  },
  {
    zone: 'Australia/Sydney',
    gmt: '(GMT+10:00)',
    name: 'Sydney',
  },
  {
    zone: 'Asia/Vladivostok',
    gmt: '(GMT+11:00)',
    name: 'Vladivostok',
  },
  {
    zone: 'Asia/Magadan',
    gmt: '(GMT+12:00)',
    name: 'Magadan',
  },
  {
    zone: 'Pacific/Auckland',
    gmt: '(GMT+12:00)',
    name: 'Auckland',
  },
  {
    zone: 'Pacific/Fiji',
    gmt: '(GMT+12:00)',
    name: 'Fiji',
  },
];

export const currencies = [
  {
    value: 'EUR',
    label: 'EUR - Euro',
  },
  {
    value: 'MXN',
    label: 'MXN - Peso Mexicano',
  },
  {
    value: 'PEN',
    label: 'PEN - Sol peruano',
  },
  {
    value: 'USD',
    label: 'USD - Dólar americano',
  },
  {
    value: 'CRC',
    label: 'CRC - Colón costarricense',
  },
  {
    value: 'COP',
    label: 'COP - Peso colombiano',
  },
  {
    value: 'GTQ',
    label: 'GTQ - Quetzal guatemalteco',
  },
];

export const paymentMethodList: any = [
  {
    value: 'RedsysBizumPaymentGateway',
    label: 'Bizum (Redsys)',
  },
  {
    value: 'PaycometBizumPaymentGateway',
    label: 'Bizum (Paycomet)',
  },
  {
    value: 'MoneiBizumPaymentGateway',
    label: 'Bizum (Monei)',
  },
  {
    value: 'GreenPayPaymentGateway',
    label: 'GreenPay',
  },
  {
    value: 'InespayPaymentGateway',
    label: 'Inespay',
  },
  {
    value: 'IngenicoPaymentGateway',
    label: 'Ingenico',
  },
  {
    value: 'PaycometMbwayPaymentGateway',
    label: 'MBWAY (Paycomet)',
  },
  {
    value: 'MoneticoPaymentGateway',
    label: 'Monetico',
  },
  {
    value: 'MymoidPaymentGateway',
    label: 'MyMoid',
  },
  {
    value: 'PaycometPaymentGateway',
    label: 'Paycomet',
  },
  {
    value: 'PaycometMultibancoSIBSPaymentGateway',
    label: 'Paycomet (Multibanco)',
  },
  {
    value: 'RedsysPaymentGateway',
    label: 'Redsys',
  },
  {
    value: 'StripePaymentGateway',
    label: 'Stripe',
  },
];

export const companyTypesList: any[] = [
  { value: 3, label: 'Empresa' },
  { value: 1, label: 'Particular' },
  { value: 2, label: 'Autónomo' },
];

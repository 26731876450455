import { ValidatorFn } from '@angular/forms';
import { CalendarTypeView } from 'primeng/calendar';

export enum WideTextArea {
  SMALL = '1',
  MEDIUM = '3',
  LARGE = '5',
  EXTRALARGE = '8',
}

export class FormInputBase<T> {
  placeholder: T | string | undefined;
  value: any;
  key: string;
  label: string;
  required: boolean;
  falseRequired?: boolean;
  order: number;
  controlType: string;
  type: string;
  validators: ValidatorFn[] | null;
  description?: string;

  //STYLES
  size?: string; // MIN-W PIXELS

  //VISIBILITY
  isVisible?: boolean;
  editable?: boolean;
  readonly: boolean;
  disabled: boolean;

  //DROPDOWN
  options: { label: string; value: string | boolean }[] | any;
  multi?: boolean;
  selectionLimit?: number;
  filter?: boolean;
  showClear?: boolean;

  //TEXTAREA
  maxCharacters?: any;
  hasSegments?: boolean;
  currentCharacters?: any;
  placeholderSelector?: boolean;
  customPlaceholders?: { label: string; value: string }[];
  wideTextArea?: WideTextArea;

  //DATES
  selectionMode?: 'range';
  maxDate?: Date;
  minDate?: Date;
  showTime?: boolean = false;
  dateView?: CalendarTypeView;

  //MISCELANEOUS
  infotooltip?: string;
  infoVisibility?: boolean;
  tooltipPosition?: 'right' | 'left' | 'top' | 'bottom';
  currency: string;
  addon: string;
  showChipCount?: boolean;
  chipCount?: number;

  //NUMBER
  min?: number | null;

  //INPUT GROUP
  inputGroup?: boolean;
  constructor(
    options: {
      placeholder?: string;
      value?: any;
      key?: string;
      label?: string;
      required?: boolean;
      falseRequired?: boolean;

      order?: number;
      controlType?: string;
      type?: string;
      description?: string;
      validators?: ValidatorFn[] | null;

      readonly?: boolean;
      disabled?: boolean;

      //SYLES
      size?: string;

      //VISIBILITY
      isVisible?: boolean;
      selectionMode?: undefined | 'range';
      editable?: boolean;

      //TEXTAREA
      maxCharacters?: number;
      hasSegments?: boolean;
      currentCharacters?: number;
      placeholderSelector?: boolean;
      customPlaceholders?: { label: string; value: string }[];
      wideTextArea?: WideTextArea;

      //DROPDOWN
      filter?: boolean;
      multi?: boolean;
      selectionLimit?: number;
      options?: { label: string; value: string | boolean }[] | any;
      showClear?: boolean;

      //DATES
      maxDate?: Date;
      minDate?: Date;
      showTime?: boolean;
      dateView?: CalendarTypeView;

      //MISCELANEOUS
      infotooltip?: string;
      infoVisibility?: boolean;
      tooltipPosition?: 'right' | 'left' | 'top' | 'bottom';
      currency?: string;
      addon?: string;
      showChipCount?: boolean;
      chipCount?: number;

      //NUMBER
      min?: number | null;

      //INPUT GROUP
      inputGroup?: boolean;
    } = {},
  ) {
    this.placeholder = options.placeholder;
    this.value = options.value;
    this.key = options.key || '';
    this.label = options.label || '';
    this.required = !!options.required;
    this.falseRequired = options.falseRequired;
    this.order = options.order === undefined ? 1 : options.order;
    this.controlType = options.controlType || '';
    this.type = options.type || '';
    this.description = options.description || '';
    this.validators = options.validators || [];

    this.size = options.size;

    this.readonly = !!options.readonly;
    this.disabled = options.disabled || false;
    this.isVisible = options.isVisible || true;
    this.editable = options.editable;
    this.hasSegments = options.hasSegments;

    this.options = options.options || [];
    this.multi = options.multi;
    this.selectionLimit = options.selectionLimit;
    this.filter = options.filter;
    this.showClear = options.showClear || true;

    //TEXTAREA
    this.maxCharacters = options.maxCharacters;
    this.currentCharacters = options.currentCharacters;
    this.placeholderSelector = options.placeholderSelector;
    this.customPlaceholders = options.customPlaceholders;
    this.wideTextArea = options.wideTextArea;

    this.selectionMode = options.selectionMode || undefined;
    this.maxDate = options.maxDate;
    this.minDate = options.minDate;
    this.showTime = options.showTime || false;
    this.dateView = options.dateView || ('date' as CalendarTypeView);

    this.infotooltip = options.infotooltip;
    this.infoVisibility = options.infoVisibility || false;
    this.tooltipPosition = options.tooltipPosition;
    this.currency = options.currency || 'EUR';
    this.addon = options.addon || '';
    this.showChipCount = options.showChipCount || false;
    this.chipCount = options.chipCount || 0;

    this.min = options.min;

    this.inputGroup = options.inputGroup;
  }

  setVisibility(isVisible: boolean): void {
    this.isVisible = isVisible;
  }

  setDisabled(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  static setReadonly(formInputs: FormInputBase<any>[], readonly: boolean): void {
    formInputs.forEach((formInput) => {
      if (!formInput.editable) {
        return;
      }
      formInput.readonly = readonly;
    });
  }

  static setDisabled(formInputs: FormInputBase<any>[], disabled: boolean): void {
    formInputs.forEach((formInput) => {
      if (!formInput.editable) {
        return;
      }
      formInput.disabled = disabled;
    });
  }

  setOptions(options: { label: string; value: string }[]): void {
    this.options = options;
  }

  setValidators(validators: ValidatorFn[]): void {
    this.validators = validators;
  }

  clearValidators(): void {
    this.validators = null;
  }
  setPropertyValue(property: keyof this, value: any): void {
    this[property] = value;
  }
}

import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ReportTypes } from '@modules/report/model/report.model';
import { filtersDashboards, filtersDashboardsCampaigns } from './config/dashboard-filters.config';
import { ReportService } from '@modules/report/services/report.service';
import { Collector } from '@shared/components/dashboard/Domain/ICollector.interface';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
})
export class DashboardComponent implements OnInit, AfterViewInit {
  isLoading$: boolean = false;
  url?: SafeResourceUrl;
  reportType!: ReportTypes;
  noDataFilters: boolean = false;
  filtersDashboards: any = filtersDashboards;
  filtersValues: {
    reportType: string;
    collectors: any;
    portfolios: any;
    campaigns: any;
    operationStatus: any;
    isMultiCurrency: boolean;
  } = {
    reportType: '',
    collectors: [],
    portfolios: [],
    campaigns: [],
    operationStatus: [],
    isMultiCurrency: false,
  };
  collectors: Collector[] = [];

  constructor(
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private reportsService: ReportService,
  ) {}

  ngOnInit(): void {
    this.route.data.subscribe((data: any) => {
      this.reportType = data['reportType'];
      this.filtersValues['reportType'] = data['reportType'];
      this.filtersValues['collectors'] = data['collectors'];
      this.collectors = data['collectors'];
      this.filtersValues['portfolios'] = data['portfolios'];
      this.filtersValues['campaigns'] = data['campaigns'];
      this.filtersValues['operationStatus'] = data['operationStatus'];
    });
    this.filtersValues['isMultiCurrency'] = this.haveSameCurrency(this.collectors);
  }

  ngAfterViewInit(): void {
    const initCollector = { collectorId: this.collectors[0].id };
    if (!this.filtersValues['isMultiCurrency']) {
      this.getIframeUrl(undefined, true);
    } else {
      this.getIframeUrl(initCollector);
    }
  }

  getIframeUrl(filters?: any, firstLoad?: boolean): void {
    if (this.reportType === ReportTypes.CAMPAIGNS_BY_MONTH && firstLoad) {
      filters = this.getInitialFilters();
    }
    this.reportsService.getReportV2(this.reportType, filters).subscribe((url: string) => {
      if (url) {
        this.url = this.sanitizer.bypassSecurityTrustResourceUrl(url);
      }
    });
  }
  private getInitialFilters(): any {
    const firstDayOfCurrentMonth = new Date(new Date().getFullYear(), new Date().getMonth(), 1);

    return {
      campaignId: 'no-workflow',
      dateMonthNotification: firstDayOfCurrentMonth,
    };
  }

  getValues(event: any): void {
    if (event.isValid) {
      this.noDataFilters = false;
      this.getIframeUrl(event.data);
    } else {
      this.noDataFilters = true;
    }
  }

  generateFilterInputs(): any {
    if (this.filtersValues['campaigns']) {
      this.filtersDashboards = filtersDashboardsCampaigns;
    } else {
      this.filtersDashboards = filtersDashboards;
    }
    return this.filtersDashboards;
  }

  haveSameCurrency(collectors: Collector[]): boolean {
    if (!collectors || collectors.length === 0) {
      return false;
    }
    const firstCurrency = collectors[0].currency;
    return !collectors.every((collector) => collector.currency === firstCurrency);
  }
}
